// store.js
import { createSlice } from '@reduxjs/toolkit';

const promptSlice = createSlice({
  name: 'prompts',
  initialState: {
    prompts: [], // For storing the initial prompt data
    filteredPrompts: [], // For search results
    query: '', // Search query
  },
  reducers: {
    setPrompts(state, action) {
      state.prompts = action.payload;
      state.filteredPrompts = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
      state.filteredPrompts = state.prompts.filter((prompt) =>
        prompt.title.toLowerCase().includes(action.payload.toLowerCase())
      );
    },
  },
});

export const { setPrompts, setQuery } = promptSlice.actions;

export default promptSlice.reducer;
