import React from 'react'
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Buttons';
import Cards from '../../components/Cards';
import { explorePlanAddonsData } from '../../constants/data';
import { PillsBadge } from '../../components/Badge';
import ProgressBar from '../../components/Progressbar';
import Icons from '../../components/Icons';
import Lists from '../../components/Lists';

function Plan() {
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues,
  });


  const handleSave = () => {
    try {
      console.log(getValues())
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Cards variant='secondary' classname='p-6' childClassName={'flex-col'}>
      <form onSubmit={handleSubmit(handleSave)} className='grid grid-cols-1 divide-y-2'>
        <div className="flex items-start justify-between pb-4">
          <div>
            <h4>Plan</h4>
            <h5 className="font-normal">Update your photo and personal details here.</h5>
          </div>
        </div>
        <div className='flex lg:flex-row flex-col gap-6 lg:basis-auto basis-full lg:py-4 py-4'>
          <div className='lg:basis-8/12 lg:py-4 p-0'>
            <Cards variant='secondary' childClassName={'flex-col gap-3 p-5'}
              bottomButton={<Button type="text" iconPosition='end' icon={<Icons icon={'heroicons-outline:arrow-up-right'} />}>Change Plan</Button>}>
              <PillsBadge title='Monthly' />
              <h4 className='text-green-700'>{explorePlanAddonsData.plan.title}</h4>
              <h1>{explorePlanAddonsData.plan.currency}{explorePlanAddonsData.plan.price.monthly}/mth</h1>
              <p className='pb-2'>{explorePlanAddonsData.plan.description}</p>
              <ProgressBar progress={12} totalValue={20} />
            </Cards>
          </div>
          <div className='lg:basis-4/12 lg:py-4 p-0'>
            <Cards variant='secondary' classname='p-4 h-full justify-between' childClassName={'flex-col'}>
              <Lists
                title={explorePlanAddonsData.title}
                description={explorePlanAddonsData.description}
                items={explorePlanAddonsData.items}
              // icon={<Icons icon={'heroicons-outline:arrow-up-right'} />}
              />
              <Button type='primary'>Upgrade</Button>
            </Cards>
          </div>
        </div>
      </form>
    </Cards>
  )
}

export default Plan