import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  BaseQueryFn,
} from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { getAuth } from "@firebase/auth";

interface CustomFetchBaseQueryArgs extends FetchArgs {
  customRedirect?: boolean;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("oogw.ai_user");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// Token refresh logic
const refreshAuthToken = async (): Promise<string | null> => {
  const auth = getAuth();
  try {
    const refreshedToken = await auth.currentUser?.getIdToken(true);
    if (refreshedToken) {
      localStorage.setItem("oogw.ai_user", refreshedToken);
      return refreshedToken;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
  }
  return null;
};

// Custom fetch base query with token handling
const customFetchBaseQuery: BaseQueryFn<
  string | CustomFetchBaseQueryArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  console.log("result",result);

  if (result?.error && result?.error?.status === 401) {
    // Attempt to refresh token if 401 Unauthorized occurs
    console.log("401 error",result.error);
    
    const newToken = await refreshAuthToken();
    if (newToken) {
      console.log("newTokencalling",newToken);
      
      // Retry the original request with the new token
      const newArgs =
        typeof args === "string"
          ? args
          : {
              ...args,
              headers: {
                ...args.headers,
                Authorization: `Bearer ${newToken}`,
              },
            };

      result = await baseQuery(newArgs, api, extraOptions);
    } else {
      // Handle case where refresh failed
      // Optionally, clear local storage and redirect to login
      localStorage.removeItem("oogw.ai_user");
      window.location.href = "/login";
      toast.error("Session expired. Please log in again.");
    }
  }

  if (result?.error && (result?.error?.status === "FETCH_ERROR" || result?.error?.status === 502)) {
    toast.error("Bad Gateway error occurred");
  }

  return result;
};

// API Slice
export const baseApi = createApi({
  reducerPath: "baseApiReducer",
  baseQuery: customFetchBaseQuery,
  endpoints: () => ({}),
  tagTypes: ["generalSettings", "notificationSettings", "metaIntegration", "teams","billing", "dashboard"],
});
