import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import Cards from "../../components/Cards";
import InputField from "../../components/InputField";
import { validationConstants } from "../../constants/validations";
import { Button } from "../../components/Buttons";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";

function Security() {
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  }: any = useForm();
  const [loading, setLoading] = useState(false);

  const handleSave = async (data: any) => {
    const { currentPassword, newPassword } = data;
    setLoading(true)
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        toast.error("User is not authenticated.");
        return;
      }

      // Re-authenticate the user
      const credential = EmailAuthProvider.credential(user.email as string, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Update the password
      await updatePassword(user, newPassword);
      toast.success("Password updated successfully.");
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      switch (error.code) {
        case "auth/wrong-password":
          toast.error("The current password is incorrect.");
          break;
        case "auth/weak-password":
          toast.error("The new password is too weak.");
          break;
        default:
          toast.error("Error: " + error.message);
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  
  return (
    <Cards variant="secondary" classname="p-6" childClassName="flex-col">
      <div className="border-b border-solid border-gray-300 pb-4">
        <h4>Password</h4>
        <h5 className="font-normal">Please enter your current password to change your password.</h5>
      </div>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className="space-y-4">
          <div className="flex flex-col w-[40%] border-b border-solid border-gray-300 py-4 gap-4">
            <InputField
              control={control}
              label="Current password"
              placeholder="Enter your current password"
              type="password"
              name="currentPassword"
              rules={{
                required: "Current password is required",
                ...validationConstants.password,
              }}
              error={errors.currentPassword}
            />
            <InputField
              control={control}
              label="New password"
              placeholder="Enter your new password"
              type="password"
              name="newPassword"
              rules={{
                required: "New password is required",
                ...validationConstants.password,
              }}
              error={errors.newPassword}
            />
            <InputField
              control={control}
              label="Confirm new password"
              placeholder="Confirm your new password"
              type="password"
              name="confirmNewPassword"
              rules={{
                required: "Confirm new password is required",
                validate: (value: string) =>
                  value === getValues("newPassword") || "Passwords do not match",
              }}
              error={errors.confirmNewPassword}
            />
          </div>
          <div className="flex justify-end gap-3 w-[40%]">
            <Button variant="outlined">Cancel</Button>
            <Button onClick={handleSubmit(handleSave)} type="primary">
              Update Password
            </Button>
          </div>
        </div>
      </form>
    </Cards>
  );
}

export default Security;
