import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null, // or set an initial value if needed
  // other state properties
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      const { name, email, uid } = action.payload;
      state.user = { name, email, uid };
    },
  },
});


export const { setUserData } = userDataSlice.actions;
export default userDataSlice.reducer;

