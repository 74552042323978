import React from "react";
import { useSelector } from "react-redux";
import { OogwaiLogo2 } from "./Icons/iconImages";

const Loading = () => {
  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <div className="relative animate-pulse">
          <OogwaiLogo2 className="m-auto animate-fade-in" />
        </div>
        {isAuth && (
          <span className="inline-block mt-1 font-medium text-sm animate-pulse">
            Loading ...
          </span>
        )}
      </div>
    </div>
  );
};



export default Loading;
