import React, { useEffect } from 'react'
import Icons from '../Icons';

import './styles.css'
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import AnimatedSVG from '../Loader';

function PromptSuggestions({ className, title = "Some commonly used prompts", promptData = [], isLoadingPrompt = false }) {
  const containerRef = React.useRef(null);
  const promptDescRef = React.useRef(null);
  const [selectedPrompt, setSelectedPrompt] = React.useState(null);

  useEffect(() => {
    setSelectedPrompt(promptData[0]);
  }, [promptData]);

  const handleMouseOver = (index) => {
    setSelectedPrompt(promptData[index]);
  };

  React.useLayoutEffect(() => () => {
    const badgePills = containerRef.current?.querySelectorAll('.badge-pill') || null;
    if (!badgePills) return null;

    const tl = gsap.timeline();

    tl
      .fromTo(
        badgePills,
        { opacity: 0, x: -20 }, // Initial state
        {
          opacity: 1,
          x: 0,
          stagger: 0.2, // Stagger delay between items
          duration: 0.5,
          ease: 'power1.out',
        }
      );

    return () => {
      tl.kill();
    };

  }, [promptData]);

  React.useLayoutEffect(() => {
    if (!selectedPrompt) return;

    const tl = gsap.fromTo(
      promptDescRef.current,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 0.5,
        ease: 'power1.out',
      }
    );

    return () => {
      tl.kill();
    };

  }, [selectedPrompt]);

  return (
    <div className='flex flex-row'>
      <div className='basis-[21%]'></div>
      {isLoadingPrompt ? <AnimatedSVG className={""} /> :
        <>
          <div className={`space-y-2 ${className} basis-[42%] pr-2`} ref={containerRef}>
            {title && <p className='text-text-600 text-sm'>{title}</p>}
            {promptData.map((item, index) => {
              const isActive = selectedPrompt?.title === item.title;
              return (
                <div
                  className={`badge-pill flex flex-row items-center justify-between w-fit gap-1 cursor-pointer ${isActive ? 'border-[#CAE8DA] bg-[#F5FAF7] text-[#4C8067]' : 'text-[#414651]'}`}
                  key={index}
                  onMouseOver={() => handleMouseOver(index)}
                >
                  <p className='pl-2'>{item.title}</p>
                  <button className={`border-2 p-1 border-solid bg-white rounded-full ${isActive ? 'border-[#CAE8DA]' : ''}`}>
                    <Icons icon="heroicons-outline:arrow-up" className={isActive ? 'text-[#26BF77]' : 'text-text-600'} />
                  </button>
                </div>
              );
            })}
          </div>
          <Link className='prompt-desc basis-[30%] mt-8 overflow-y-scroll' ref={promptDescRef}>
            {selectedPrompt && <div dangerouslySetInnerHTML={{ __html: selectedPrompt.desc }} />}
          </Link>
        </>}
    </div>
  );
}



export default PromptSuggestions;