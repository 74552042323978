import { useEffect, useRef, useState } from "react";

// COMPONENTS
import Cards from "../../components/Cards";
import CustomChart from "../../components/ChartComponents/BarChart";
import { dataSourcesTabs, dateRange, durationDataTabs, promptData } from "../../constants/data";
import InputField from "../../components/InputField";
import { FormProvider, useForm } from "react-hook-form";
import PromptSuggestions from "../../components/PromptSuggestions";
import { Select } from "antd";
import Icons from "../../components/Icons";
import Sidebar from "../../components/Sidebar";
import TextAreaComponent from "../../components/TextArea";
import Checkbox from "../../components/Checkbox";
import { CustomSegmented } from "../../components/HeaderNav/Tabs";
import { InfoSettings, ControlIcon, BrandGAnalytics, RightArrowIcon, OogwaiLogo3, ChevronOutlineRightIcon, Trash2Icon } from "../../components/Icons/iconImages";
import Accordion from "../../components/Accordion";
import { Button } from "../../components/Buttons";

import FirstTime from "./firsttime";
import { useGetAnalyticStatusQuery, useGetListAnalyticsAccountsQuery } from "../../services/integrationSlice";
import Loading from "../../components/Loading";
import gsap from "gsap";

import rawData from './../../constants/chartData.json';
import { useLazyAskGoogleAnalyticsQuestionQuery } from "../../services/dashboardSlice";
import { toast } from "react-toastify";
import AnimatedSVG from "../../components/Loader";


const Dashboard = () => {
  const [selectedRange, setSelectedRange] = useState('allTime');
  const methods = useForm();

  // TODO : The Accordion needs to be dynamic component + fix the search button
  // ! temporary swtich
  const [toggleDashboard, setToggleDashboard] = useState(true);
  const { data: analyticStatus, isLoading } = useGetAnalyticStatusQuery();
  const { register, control, getValues } = methods;

  // USER QUESTION INPUT
  const { data: listAnalyticsData } = useGetListAnalyticsAccountsQuery({})
  const [askGoogleAnayticsQuestion, { isFetching: isQuestionLoading, data: questionData }] = useLazyAskGoogleAnalyticsQuestionQuery({
    refetchOnMountOrArgChange: true
  });

  const analyticStatusData = {
    ...analyticStatus,
    message: false,
  }

  // ! ----- TESTING -----
  // Utility function to filter data based on selected date range
  const filterDataByRange = (data, range) => {
    const today = new Date();
    let startDate;

    switch (range) {
      case "allTime":
        return data; // Return all data
      case "last1Year":
        startDate = new Date(today.setFullYear(today.getFullYear() - 1));
        break;
      case "last6Month":
        startDate = new Date(today.setMonth(today.getMonth() - 6));
        break;
      case "last3Month":
        startDate = new Date(today.setMonth(today.getMonth() - 3));
        break;
      case "last1Month":
        startDate = new Date(today.setMonth(today.getMonth() - 1));
        break;
      case "last7Days":
        startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      default:
        return data; // Return all data
    }


    return data.filter((item) => new Date(item.date) >= startDate);
  };

  const getFormattedData = (rawData, range) => {
    const filteredData = filterDataByRange(rawData, range);
    if (range === 'last1Month') {
      return filteredData;
    }
    const monthlyData = filteredData.reduce((result, item) => {
      const [year, month] = item.date.split('-');
      const key = `${year}-${month}`;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);

      return result;
    }, {});

    return Object.values(monthlyData).flatMap((monthData) => {
      const lastItem = monthData[monthData.length - 1];
      const sampleItems = monthData.slice(0, 3);
      if (!sampleItems.includes(lastItem)) {
        sampleItems.push(lastItem); // Ensure the last date of the month is included.
      }
      return sampleItems;
    });
  }

  const formattedData = getFormattedData(rawData, selectedRange);

  const xTickFormatter = (value, index) => {
    const date = new Date(value);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    if (selectedRange.includes('last7Days')) {
      return date.toLocaleDateString('en-US', { weekday: 'short' });
    }
    if (selectedRange.includes('last1Month', 'last3Month', 'last6Month')) {
      return `${date.getDate()} ${date.toLocaleDateString('en-US', { month: 'short' })}`;
    }
    if (date.getDate() === lastDayOfMonth) {
      return date.toLocaleDateString('en-US', { month: 'short' });
    }
    return '';
  }

  const yTickYFormatter = (value, index) => {
    return `${value.toFixed(0)}%`
  }

  // SIDEBAR STATE MANAGEMENT
  const [toggleSidebar, setToggleSidebar] = useState();
  const [activeWidget, setActiveWidget] = useState(null); // Tracks the currently active widget
  const [isAddingWidget, setIsAddingWidget] = useState(false); // Flag for adding widget

  // DYNAMIC WIDGETS
  const colorScheme = ['#1F7A50', '#DC6803', '#E15759', '#FFC107', '#457B9D'];
  const [widgets, setWidgets] = useState([
    {
      id: 1,
      name: "Conversion Rate",
      propmpt: 'Latest Conversion Rate Trends for Oogw.ai',
      conversionRate: 12,
      conversionDate: new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(Date.now() - Math.floor(Math.random() * 10000000000))).replace(/\s/g, '-'),
      metric: {
        date: new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date(Date.now() - Math.floor(Math.random() * 10000000000))).replace(/\s/g, '-'),
        dateRange: 'allTime',
        value: 12,
        valueType: '%',
      },
      chart: {
        data: formattedData,
        dateRange: 'allTime',
        dataXKey: 'date',
        dataYKey: 'value',
        xAxisLabel: 'Month',
        yAxisLabel: 'Conversion Rate',
        type: 'bump',
      },
      colorScheme: colorScheme[Math.floor(Math.random() * colorScheme.length)],
    },
  ]);

  const widgetsContainerRef = useRef(null);

  // Handle timeline changes for a specific widget
  const handleTimelineChange = (id, range) => {
    setSelectedRange(range);
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.id === id
          ? {
            ...widget,
            chart: {
              ...widget.chart,
              data: filterDataByRange(formattedData, range),
            },
          }
          : widget
      )
    );
  };

  const handleAddWidget = () => {
    setIsAddingWidget(true);
    const newId = widgets.length ? widgets[widgets.length - 1].id + 1 : 1;
    const newWidget = {
      id: newId,
      name: `Widget ${newId}`,
      propmpt: `Latest Conversion Rate Trends for Oogw.ai ${newId}`,
      conversionRate: Math.floor(Math.random() * (20 - 10 + 1)) + 10,
      conversionDate: new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      }).format(new Date(Date.now() - Math.floor(Math.random() * 10000000000))).replace(/\s/g, "-"),
      metric: {
        dateRange: new Intl.DateTimeFormat("en-GB", {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(Date.now() - Math.floor(Math.random() * 10000000000))).replace(/\s/g, "-"),
        value: Math.floor(Math.random() * (20 - 10 + 1)) + 10,
        valueType: "%",
      },
      chart: {
        data: formattedData.map((data) => ({
          ...data,
          Trend: Math.floor(Math.random() * (2000 - 500 + 1)) + 500,
        })),
        dataXKey: "date",
        dataYKey: "value",
        xAxisLabel: "Month",
        yAxisLabel: "Total users",
        type: "bar",
      },
      colorScheme: colorScheme[Math.floor(Math.random() * colorScheme.length)],
    };

    setWidgets((prevWidgets) => {
      const updatedWidgets = [...prevWidgets, newWidget];

      setTimeout(() => {
        const lastWidgetElement = document.getElementById(`widget-${newId}`);
        if (lastWidgetElement) {
          lastWidgetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        setIsAddingWidget(false);
      }, 100);

      return updatedWidgets;
    });
  };

  const handleRemoveWidget = (id) => {
    const widgetElement = document.getElementById(`widget-${id}`);
    if (widgetElement) {
      gsap.to(widgetElement, {
        opacity: 0,
        y: -20,
        duration: 0.3,
        onComplete: () => {
          setWidgets((prevWidgets) => prevWidgets.filter((widget) => widget.id !== id));
        },
      });
    }
  };

  const handleQuestion = async () => {
    try {
      const question = getValues('questionInput');
      if (!question) return;
      const payload = {
        question: question,
        property_id: listAnalyticsData.property_ids || ''
      }
      await askGoogleAnayticsQuestion(payload)
        .unwrap()
        .then((response) => {
          if (response.data === null) {
            throw new Error('Unexpected error');
          }
          // TODO: TEMPORARY SHOWING FIRST DATA IN THE RESPONSE
          const outputData = response.data.map(item => item.output[0]);
          toast.success(`Output Data: ${outputData.join(', ')}`);
        })

    } catch (error) {
      toast.error(error?.data?.error || 'Unexpected error');
    }
  }

  const handleEditWidget = (widget) => {
    setActiveWidget(widget);
    setToggleSidebar(true);
  };

  useEffect(() => {
    const container = widgetsContainerRef.current;
    if (container && isAddingWidget) {
      const newWidget = container.lastChild;
      if (newWidget) {
        gsap.from(newWidget, {
          opacity: 0,
          y: 20,
          duration: 0.3,
        });
      }
    }
  }, [widgets]);

  if (isLoading) {
    return <Loading />
  }

  if (Object.values(analyticStatusData || {}).every((value) => !value)) {
    return <FirstTime />
  }

  return (
    <FormProvider {...methods}>
      {/* <button type="button" onClick={() => setToggleDashboard(!toggleDashboard)} className="absolute bg-gray-300 inline-flex w-fit p-2 hover:bg-gray-400">Test Switch</button> */}
      <section className="flex flex-col w-full gap-6 max-w-[1280px] mx-auto px-4">
        {!toggleDashboard &&
          <>
            <div className="content-center w-7/12 mx-auto">
              <Cards classname={'w-full rounded-2xl'} title={"Welcome to Oogw.ai"}>
                <InputField
                  onClick={() => console.log('clicked')}
                  name="questionInput"
                  hint={"e.g., ask about audience numbers, conversion rate, bounce rate, sales, etc."}
                  placeholder="start typing your question here..."
                />
              </Cards>
            </div>
            <PromptSuggestions className={'w-full'} promptData={promptData} />
          </>}
        {toggleDashboard && (
          <div className="grid grid-cols-1 divide-y gap-2">
            <div className="pb-3">
              <h4 className="font-semibold">Welcome, to your Oogw.ai Dashboard!</h4>
              <p className="text-text-600 text-sm">This is where all your query widgets live</p>
            </div>
            <div className="space-y-4 py-2">
              <div className="bg-gray-100 py-10 px-3 rounded-2xl">
                <div className="flex justify-between gap-2 max-w-[584px] mx-auto">
                  <OogwaiLogo3 className="min-w-12 h-12" />
                  <InputField
                    name="questionInput"
                    hint={"e.g., ask about audience numbers, conversion rate, bounce rate, sales, etc."}
                    placeholder="ask something"
                    control={control}
                    iconDirection={"right"}
                    customLoadingIcon={<AnimatedSVG />}
                    disabled={isQuestionLoading}
                    isLoading={isQuestionLoading}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleQuestion();
                      }
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={handleQuestion}
                    className="h-11 min-w-11"
                    loading={isQuestionLoading}
                    icon={<RightArrowIcon />}
                  />
                </div>
              </div>
              <h4 className="font-semibold">Your Widgets</h4>
              <div className="flex w-full flex-col gap-5" ref={widgetsContainerRef}>
                {widgets.length === 0 && <Button
                  type="button"
                  onClick={handleAddWidget}
                >
                  Add Widget
                </Button>}
                {widgets.map((widget) => {
                  return (
                    <div id={`widget-${widget.id}`} key={widget.id} className="flex flex-col w-full border border-[#E9EAEB] rounded-xl p-3">
                      <div className="flex items-center justify-between pb-4">
                        <div className="flex items-center gap-2">
                          <BrandGAnalytics className="w-8 h-8" />
                          <div>
                            <h4 className="font-semibold">{widget.name}</h4>
                            <p className="text-sm">{widget.propmpt}</p>
                          </div>
                        </div>
                        <div className="flex gap-2 items-center">
                          <Button
                            danger
                            icon={<Trash2Icon />}
                            type="text"
                            shape="circle"
                            onClick={() => handleRemoveWidget(widget.id)}
                          // className="h-8 w-8 text-white rounded-full"
                          />
                          <Button
                            type="default"
                            onClick={handleAddWidget}
                          >
                            Prompt
                          </Button>
                        </div>
                      </div>
                      <div className="flex w-full gap-5">
                        <Accordion
                          className={"basis-1/4 h-fit bg-gray-100"}
                          expandIcon={[
                            <Icons className="w-4 h-4 m-2" icon="heroicons-outline:plus" />,
                            <Icons className="w-4 h-4 m-2" icon="heroicons-outline:minus" />,
                          ]}
                          header={
                            <Select
                              {...register(`dateRangeMetric_${widget.id}`)}
                              onChange={(value) => {
                                methods.setValue(`dateRangeMetric_${widget.id}`, value);
                              }}
                              defaultValue={widget.metric.dateRange}
                              onClick={(e) => e.stopPropagation()}
                              placeholder="Date Range"
                              options={durationDataTabs}
                              className="rounded-full shadow-sm"
                            />
                          }
                          footer={
                            <div className="flex items-center justify-between w-full mt-4">
                              <Button shape="circle" onClick={() => handleEditWidget(widget)} icon={<ControlIcon className="w-4 h-4" />} />
                              <Button shape="circle" type="text" size="small" icon={<InfoSettings />} />
                            </div>
                          }
                        >
                          <div className="flex flex-col justify-center w-full px-1">
                            <p className="text-text-600 text-sm pt-4">{widget.metric.date}</p>
                            <h1 style={{ color: widget.colorScheme }}>{widget.metric.value}{widget.metric.valueType}</h1>
                          </div>
                        </Accordion>

                        <Cards classname={'basis-3/4 p-5'} childClassName={"flex-col justify-between"} variant="secondary">
                          <div className="flex justify-between w-full pb-4">
                            <div className="flex gap-2">
                              <Select
                                {...register(`dateRangeChart_${widget.id}`)}
                                onChange={(value) => {
                                  methods.setValue(`dateRangeChart_${widget.id}`, value);
                                  handleTimelineChange(widget.id, value);
                                }}
                                defaultValue={widget.chart.dateRange}
                                onClick={(e) => e.stopPropagation()}
                                placeholder="Date Range"
                                options={durationDataTabs}
                                className="rounded-full shadow-sm min-w-32"
                              />
                              <h4 className="text-md mt-1.5">{widget.name && `${widget.name} Trend`}</h4>
                            </div>
                            <Button type="text" icon={<ChevronOutlineRightIcon />}></Button>
                          </div>
                          <CustomChart
                            data={widget.chart.data}
                            type={widget.chart.type}
                            dataXkey={widget.chart.dataXKey}
                            dataYKey={widget.chart.dataYKey}
                            xAxisLabel={widget.chart.xAxisLabel}
                            yAxisLabel={widget.chart.yAxisLabel}
                            tickXFormatter={xTickFormatter}
                            tickYFormatter={yTickYFormatter}
                          />
                        </Cards>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>)
        }
      </section>
      <Sidebar
        open={toggleSidebar}
        onClose={() => {
          setToggleSidebar(false);
          setActiveWidget(null);
        }}
        header={
          <Button
            shape="circle"
            size="middle"
            onClick={() => {
              setToggleSidebar(false);
              setActiveWidget(null);
            }}
            icon={<ControlIcon className="w-4 h-4" />}
          />
        }
        footer={
          <Button
            type="primary"
            className="w-full"
            onClick={() => console.log("Update functionality")} // Add update functionality
          >
            Update
          </Button>
        }
      >
        {
          activeWidget && (
            <div className='space-y-3'>
              <TextAreaComponent
                control={control}
                defaultValue={activeWidget.propmpt || ''}
                // {...register('content')} 
                label={'Prompt'}
                placeholder={'Write your prompt here...'}
              />
              <Checkbox
                // control={control}
                variant={"secondary"}
                label={"Website / App"}
                desc={"Prompts to generate website related data"}
                name={"isWebsiteApp"}
              // rules={{
              //   required: false,
              // }}
              />
              <Checkbox
                // control={control}
                variant={"secondary"}
                label={"Audience"}
                desc={"Audience data prompts"}
                name={"isAudience"}
              // rules={{
              //   required: false,
              // }}
              />
              <Checkbox
                // control={control}
                variant={"secondary"}
                label={"Campaigns"}
                desc={"Prompts created for specific campaigns"}
                name={"isCampaigns"}
              // rules={{
              //   required: false,
              // }}
              />
              <Checkbox
                // control={control}
                variant={"secondary"}
                label={"Sales"}
                desc={"Prompts about sales data"}
                name={"isSales"}
              // rules={{
              //   required: false,
              // }}
              />
              <CustomSegmented
                control={control}
                name={'dataSources'}
                label={'Data Sources'}
                options={dataSourcesTabs}
              />
              <CustomSegmented
                control={control}
                name={'widget_duration'}
                label={'Duration'}
                options={durationDataTabs}
              />
            </div>
          )
        }
      </Sidebar>
    </FormProvider>
  );
};

export default Dashboard;
