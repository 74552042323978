import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { baseApi } from '../services/baseQuery';
import integrationsSlice from "./slice/integrations";
import paymentCard from "./slice/paymentCard";
import promptSlice from "./slice/search";
import authSlice from "./slice/userAuth";
import userDataSlice from "./slice/userData";


export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    integrations:integrationsSlice,
    paymentCard:paymentCard,
    query:promptSlice,
    auth:authSlice,
    userData:userDataSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;