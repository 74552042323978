import { Button } from '../../components/Buttons'
import Cards from '../../components/Cards'
import InputField from '../../components/InputField'
import { useForm, Controller } from 'react-hook-form'
import { validationConstants } from '../../constants/validations'
import FileInput from '../../components/FileInput'
import { Input, Select } from 'antd'
import { TimeZones } from '../../constants/data'
import { useGetGeneralSettingsQuery, useUpdateGeneralSettingsMutation } from '../../services/settingsSlice'
import Loading from '../../components/Loading'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

function GeneralSettings() {

  const [updateSettings] = useUpdateGeneralSettingsMutation();
  const { data: getUserData } = useGetGeneralSettingsQuery('');
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: "",
    phoneno: "",
    timeZone: "",
    profile_picture: "",
    file: ""
  };

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues,
  });

  const fileValue = watch('file');

  useEffect(() => {
    if (getUserData?.settings) {
      let values = getUserData?.settings;
      setValue('firstName', values?.first_name)
      setValue('lastName', values?.last_name)
      setValue('email', values?.email)
      setValue('phoneno', values?.phone_no)
      setValue('timeZone', values?.timezone)
    }
  }, [getUserData])

  const handleSave = async () => {
    const values: any = getValues();
    setLoading(true)
    try {
      let payload = {
        "email": values?.email,
        "first_name": values?.firstName,
        "last_name": values?.lastName,
        "phone_no": values?.phoneno,
        "timezone": values.timeZone,
        "profile_picture":values?.file ?? ""
      }

      await updateSettings(payload)
        .unwrap()
        .then((response) => {
          setLoading(false)
          toast.success(response?.message ||'Settings updated successfully');
        })
        .catch((error) => {
          console.log("error", error)
          setLoading(false)

        })
    } catch (err) {
      console.log(err)
    }
  }
  console.log("errors", errors);

  if (loading) {
    return <Loading />;
  }

  return (
    <Cards variant='secondary' classname='p-6' childClassName={'flex-col'} title={''} bottomButton={undefined}>
      <div className="flex w-full justify-between items-start border-b border-solid border-gray-300 pb-4">
        <div>
          <h4 className='text-[#181D27] font-semibold text-lg'>General</h4>
          <h5 className="font-normal text-[#535862] text-sm">Update your photo and personal details here.</h5>
        </div>
      </div>
      <form onSubmit={handleSubmit(handleSave)}  className="grid grid-cols-1  md:grid-cols-2 gap-x-7 gap-y-4 pt-3">
        <InputField
          control={control}
          label={'First Name'}
          placeholder={'Enter your first name'}
          type='text'
          name="firstName"
          rules={{
            required: {
              value: true,
              message: 'First Name is required',
            },
            ...validationConstants.firstName,
          }}
          error={errors.firstName}
        />
        <InputField
          control={control}
          label={'Last Name'}
          placeholder={'Enter your email'}
          type='text'
          name="lastName"
          rules={{
            required: {
              value: true,
              message: 'Last Name is required',
            },
            ...validationConstants.lastName,
          }}
          error={errors.lastName}
        />
        <InputField
          control={control}
          label={'Email address'}
          placeholder={'Enter your email'}
          type='email'
          name="email"
          rules={{
            required: 'Email is required',
            ...validationConstants.email,
          }}
          error={errors.email}
        />

        <div>
          <p className="text-sm text-[#414651] font-medium mb-1">Phone No<span className="pl-1 text-green-500">*</span></p>
          <Controller
            control={control}
            name="phoneno"
            rules={{
              required: {
                value: true,
                message: 'Phone No is required',
              },
              ...validationConstants.phoneno,
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <div className='flex items-center'>
                  <div className='mobileinputCode border-gray-300 text-[#535862] text-sm'>+91</div>
                  <Input
                    {...field}
                    className={`h-12 rounded-full w-full ${error ? "border-red-500" : "border-gray-300"
                      }`}
                    placeholder="Enter your phone no"
                    onChange={(value) => field.onChange(value)}
                    style={{
                      border: error ? "1px solid #F87171" : "",
                    }} />

                </div>
                {error && <p className="text-red-400 text-sm">{error.message}</p>}
              </>
            )}
          />
        </div>

        <div>
          <p className="text-sm text-[#414651] font-medium">Timezone<span className="pl-1 text-green-500">*</span></p>
          <Controller
            control={control}
            name="timeZone"
            rules={{
              required: {
                value: true,
                message: "Timezone is required",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <Select
                  {...field}
                  className={`h-12 rounded-full w-full ${error ? "border-red-500" : "border-gray-300"
                    }`}
                  options={TimeZones.map((res) => ({
                    value: res,
                    label: res,
                  }))}
                  showSearch
                  placeholder="Select a timezone"
                  onChange={(value) => field.onChange(value)}
                  style={{
                    border: error ? "1px solid #F87171" : "",
                  }}
                />
                {error && <p className="text-red-400 text-sm">{error.message}</p>}
              </>
            )}
          />
        </div>


        <div className='flex grid-cols-subgrid gap-5 col-span-2 justify-between border-b border-solid border-gray-300 pb-4'>
          <img className='max-w-20 h-fit rounded-full' src={fileValue ? fileValue : getUserData?.settings?.profile_picture ?? "https://randomuser.me/api/portraits/women/71.jpg"} alt="avatar-pic" />
          <FileInput control={control} />
        </div>
        <div className='flex justify-end gap-3 w-full col-start-2'>
          <Button
          // onClick={() => handleSignInWithGoogle()}
          // disabled={loading}
          // loading={loading}
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
          // disabled={loading}
          // loading={loading}
          onClick={handleSubmit(handleSave)}
          >
            Save changes
          </Button>
        </div>
      </form>
    </Cards>
  )
}

export default GeneralSettings