import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import './styles.css'

const DrawerNav = React.memo(() => {
  const location = useLocation();
  const currentRoute = '/company-settings';

  const activeTab = location.pathname;

  return (
    <div id="drawer-navigation" aria-labelledby="drawer-navigation-label" className='drawer-navigation'> 
      <div className="p-4 overflow-y-auto">
        <ul className="font-medium">
          <li className={`${activeTab === currentRoute+'/general' ? 'isActive': ''}`}>
            <Link to={currentRoute+'/general'} className="flex items-center rounded-lg group">
              <span>General</span>
            </Link>
          </li>
          <li className={`${activeTab === currentRoute+'/notifications' ? 'isActive': ''}`}>
            <Link to={currentRoute+'/notifications'} className="flex items-center rounded-lg group">
              <span className="flex-1 whitespace-nowrap">Notifications</span>
            </Link>
          </li>
          <li className={`${activeTab === currentRoute+'/security' ? 'isActive': ''}`}>
            <Link to={currentRoute+'/security'} className="flex items-center rounded-lg group">
              <span className="flex-1 whitespace-nowrap">Security</span>
            </Link>
          </li>
          <li className={`${activeTab === currentRoute+'/teams' ? 'isActive': ''}`}>
            <Link to={currentRoute+'/teams'} className="flex items-center rounded-lg group">
              <span className="flex-1 whitespace-nowrap">Teams</span>
            </Link>
          </li>
          <li className={`${activeTab === currentRoute+'/plan' ? 'isActive': ''}`}>
            <Link to={currentRoute+'/plan'} className="flex items-center rounded-lg group">
              <span className="flex-1 whitespace-nowrap">Plan</span>
            </Link>
          </li>
          <li className={`${activeTab === currentRoute+'/billing' ? 'isActive': ''}`}>
            <Link to={currentRoute+'/billing'} className="flex items-center rounded-lg group">
              <span className="flex-1 whitespace-nowrap">Billing</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default DrawerNav