import { FC } from "react";
import { Icon, IconifyIconProps } from "@iconify/react";
interface IconsProps extends IconifyIconProps {
  icon: IconifyIconProps["icon"];
  className?: string;
  onClick?: () => void;
  width?: number | string;
  rotate?: number;
  hFlip?: boolean;
  vFlip?: boolean;
  alt?: string;
}

const Icons: FC<IconsProps> = ({ icon, className, onClick, width, rotate, hFlip, vFlip, alt }: IconsProps) => {
  return (
    <Icon
      {...{ width, rotate, hFlip, icon, className, vFlip, onClick, alt }}
    />
  );
};


export default Icons;
