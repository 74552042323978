import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Upload } from '../Icons/iconImages';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from '../../firebase/firebase';

function FileInput({ control }: any) {

  const uploader = (file: File, onComplete: (url: string) => void) => {
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);


    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      },
      (error) => {
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: string) => {
          onComplete(downloadURL)
        })
          .catch((err: any) => {
          })

      }
    );
  }

  return (
    <div className="flex items-center justify-center w-full">
      <Controller
        name="file"
        control={control}
        render={({ field, formState }) => {
          console.log("formState",formState,field);
          
          return (
            <label
              htmlFor="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-36 border border-gray-300 rounded-lg cursor-pointer"
            >
              <div className="flex flex-col items-center justify-center gap-1">
                <div className='border border-[#E9EAEB] shadow-sm bg-white p-2 rounded-lg'>
                  <Upload className='w-6 h-6' />
                </div>
                <p className="pt-1.5 text-sm text-gray-600">
                  <span className="font-semibold text-green-500">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-600">
                  SVG, PNG, JPG or GIF (max. 800x400px)
                </p>
              </div>
              <input
                {...field}
                value={''}
                onChange={async (event: any) => {
                  const file = event.target.files[0]; // Get the selected file
                  const fileUrl = await new Promise<string>((resolve, reject) => {
                    uploader(file, (url) => {
                      if (url) {
                        resolve(url);
                      } else {
                        reject(new Error("File upload failed"));
                      }
                    });
                  });

                  field.onChange(fileUrl); // Update the form state with the file object
                }}
                id="dropzone-file"
                type="file"
                className="hidden"
              />
            </label>
          )
        }

        }
      />
    </div>
  );
}

export default FileInput