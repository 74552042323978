import { useForm } from 'react-hook-form';
import Cards from '../../components/Cards';
import TeamSelector from '../../components/TeamSelector';
import { TableTeam } from '../../components/Table';
import { teamDummyData } from '../../constants/data';
import { Checkbox, Select, SelectProps } from 'antd';
import { Button } from '../../components/Buttons';
import { TrashIcon } from '../../components/Icons/iconImages';
import Modal from 'antd/es/modal/Modal';
import { useState } from 'react';
import { useGetTeamMembersQuery, useInviteTeamMemberMutation, useRemoveTeamMemberMutation, useUpdateTeamMemberMutation } from '../../services/settingsSlice';
import { toast } from 'react-toastify';

function Teams() {
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    // defaultValues,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteTeam] = useInviteTeamMemberMutation();
  const [team, setTeam] = useState([]);
  const { data: teamList } = useGetTeamMembersQuery('');
  const [removeteamMember] = useRemoveTeamMemberMutation();
  const [updateToggle] = useUpdateTeamMemberMutation();
  console.log("teamList", teamList?.members);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const handleSave = () => {
    try {
      console.log(getValues())
    } catch (err) {
      console.log(err)
    }
  }

  const handleAddMember = () => {
    try {
      // openModal('addMember');
    } catch (err) {
      console.log(err)
    }
  }

  const options: SelectProps['options'] = teamDummyData.map(team => ({
    label: (
      <div className="flex items-center gap-2">
        <img className="w-4 h-4 rounded-full" src={team.avatar} alt={team.name} />
        <span>{team.name}</span>
      </div>
    ),
    value: team.id,
  }));

  const handleClickInvite = () => {
    const payload = {
      "invited_user_emails": team
    }
    inviteTeam(payload)
      .unwrap()
      .then((res: any) => {
        console.log("responseeeeeee", res);
        if (res) {
          toast.success(res?.message || 'Member added successfully from the team.');
          handleCancel();
          setTeam([])
        }
      })
      .catch((error: any) => {
        console.log("dfferrrrrrrrr", error);
        toast.error(error?.data?.message || 'Error while create');

      })
  }

  const handleDelete = (id: any) => {
    const payload = {
      "member_email": id
    }
    removeteamMember(payload)
      .unwrap()
      .then((res: any) => {
        console.log("responseeeeeee", res);
        if (res) {
          toast.success(res?.message || 'Removed successfully from the team.');

        }
      })
      .catch((error: any) => {
        console.log("dfferrrrrrrrr", error);
        toast.error(error?.data?.message || 'Error while delete');
      })
  }

  const handleCheckedToggle = (checked: boolean) => {
    updateToggle(checked)
      .unwrap()
      .then((res: any) => {
        console.log("responseeeeeee", res);
        if (res) {
          toast.success(res?.message || 'Member');
        }
      })
      .catch((error: any) => {
        console.log("dfferrrrrrrrr", error);
        toast.error(error?.data?.message || 'Error while update');
      })
  }
  return (
    <>
      <Cards variant='secondary' classname='p-6' childClassName={'flex-col'} title={''} bottomButton={undefined}>
        <form onSubmit={handleSubmit(handleSave)} className='grid grid-cols-1 divide-y-2'>
          {/* <div className="flex items-start justify-between pb-4">
            <div>
              <h4>Teams</h4>
              <h5 className="font-normal text-[#535862]">You’re on the following teams. You can create a new team here.</h5>
            </div>
            <Button type='primary'>Add Team</Button>
          </div> */}
          {/* <div className='w-full py-4'>
            <p className='text-md font-medium text-[#414651]'>On teams</p>
            <p className="font-normal text-[#535862]">You’re currently on these teams.</p>
          </div> */}
          {/* <div className='grid grid-cols-1 divide-y-2 w-full'>
            <TeamSelector control={control} label={'Amazon'} subLabel={'Admin'} teamLogo={'https://logo.uplead.com/amazon.com'} defaultValue={true} />
            <TeamSelector control={control} label={'Flipkart'} subLabel={'Member'} teamLogo={'https://logo.uplead.com/flipkart.com'} defaultValue={false} />
            <TeamSelector control={control} label={'Google'} subLabel={'Member'} teamLogo={'https://logo.uplead.com/google.com'} defaultValue={false} />
          </div> */}
          <div className='w-full py-8'>
            <div className='grid grid-cols-12'>
              <div className='col-span-10'>
                <p className='text-md font-semibold text-[#181D27]'>Your team</p>
                <h5 className="font-normal text-[#535862]">Manage your existing team and change roles/permissions.</h5>
              </div>
              <div className='flex gap-3 items-center justify-end col-span-2'>
                {/* <Select
                  defaultValue="Layers"
                  className='h-10 rounded-full w-full'
                  options={[
                    { value: 'Layers', label: 'Layers' },
                    { value: 'lucy', label: 'Lucy' },
                    { value: 'Yiminghe', label: 'yiminghe' },
                    { value: 'disabled', label: 'Disabled', disabled: true },
                  ]}
                /> */}
                <Button onClick={showModal} color="primary" variant="outlined">Add Member</Button>
              </div>
            </div>
            <div className='mt-3'>
              <div className='flex flex-col w-full gap-4'>
                {/* <TeamSelector control={control} label={'Amazon'} subLabel={'amazon.in'} teamLogo={'https://logo.uplead.com/amazon.com'} defaultValue={false} /> */}
                <TableTeam data={teamList?.members ?? []} onEdit={() => { }} onDelete={(id: any) => {
                  handleDelete(id)
                }} updateToggle={(checked: any) => {
                  handleCheckedToggle(checked)
                }} />
              </div>
            </div>
          </div>
        </form>
      </Cards>
      <Modal
        className='p-0'
        width={'60%'}
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        onOk={() => {
          console.log('save')
        }}
      >
        <div className='grid grid-cols-1 divide-y divide-[#E9EAEB]'>
          <div className='p-6'>
            <h4>Invite Members</h4>
            <p>Team- Layers</p>
          </div>
          <div className='bg-gray-100'>
            <div className='flex p-6 gap-2'>
              <Select
                mode="tags"
                allowClear
                className='rounded-full w-full shadow-sm'
                placeholder="Please select"
                options={[]}
                value={team}
                onChange={(e) => {
                  console.log("lkkkkkkkkkkkkkkkkkkkkkk", e);
                  setTeam(e)
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <Button type='primary' onClick={handleClickInvite}>Invite</Button>
            </div>
            {/* <div className='grid grid-cols-1 divide-y divide-[#E9EAEB]'>
              {teamDummyData.map((item: any, index: number) =>
                <div className='grid grid-cols-5 px-6 py-3.5 items-center'>
                  <p className='col-span-2 font-medium'>{item.name}</p>
                  <p className='col-span-2'>{item.email}</p>
                  <div className='flex flex-row col-span-1 items-center justify-between'>
                    {item.inviteStatus === 'accepted' ? <>
                      <Checkbox defaultChecked={item.isAdmin} onClick={(e) => e.stopPropagation()}>
                        Admin
                      </Checkbox>
                      <Button type="text" icon={<TrashIcon className="text-[#A4A7AE]" />}></Button>
                    </> : <p>{item.inviteStatus}</p>}
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Teams