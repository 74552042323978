import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Cards from '../../components/Cards';
import RadioButton from '../../components/Radio';
import { billingData } from '../../constants/data';
import InputField from '../../components/InputField';
import { validationConstants } from '../../constants/validations';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/Buttons';
import gsap from 'gsap';
import Sidebar from '../../components/Sidebar';
import { addCard, deleteCard } from '../../store/slice/paymentCard';
import { Mastercard, VisaLogo } from '../../components/Icons/iconImages';
import Checkbox from '../../components/Checkbox';
import Icons from '../../components/Icons';
import { Drawer } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RootState } from '../../store';
import PaymentForm from '../../components/billingSettings/paymentCard';
import { useCreateCardMutation, useGetCardQuery, useMakeDefaultCardMutation, useSavePaymentEmailMutation, useUpdateCardMutation } from '../../services/settingsSlice';
import { toast } from 'react-toastify';

interface PaymentFormValues {
  name: string;
  card_number: string;
  expiry_date: string;
  cvv: string;
}

const Billing = () => {
  const { user } = useSelector((state: RootState) => state.userData);
  return (
    <>
      <NestedBilling user={user} />
    </>
  )
}

function NestedBilling({ user }: any) {
  const dispatch = useDispatch();
  const [opened, { open, close }] = useDisclosure(false);

  const [createCard] = useCreateCardMutation();
  const { data: getCardData } = useGetCardQuery('');
  const [saveBillingEmail] = useSavePaymentEmailMutation()
  const [currectCard, setCurrentCard] = useState<any>({});
  const [editCard, setEditCard] = useState(false);
  const [makeDefaultCard] = useMakeDefaultCardMutation();
  const [updateCard, { isLoading }] = useUpdateCardMutation();

  const { control, handleSubmit, reset } = useForm<any>({
    defaultValues: {
      paymentMethods: [], // Initialize an empty array for payment methods
      email: "isAccountEmail", // Default selection for email
      alternativeEmail_input: "", // Alternative email field
    },
  });


  const handleSave = (data: any) => {
    const payload = {
      "email": data?.alternativeEmail_input
    }
    saveBillingEmail(data?.email === "isAlternativeEmail" ? payload : {})
      .unwrap()
      .then((res: any) => {
        if (res) {
          close();
          toast.success(res?.success || 'Data saved successfully!');
        }
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Error while create');
      })
  };

  const handleEditCard = (card: any) => {
    open()
    setEditCard(true)
    setCurrentCard(card)
  };

  const setAsDefault = (id: string) => {
    let update = {
      data: {
        "is_default": true
      },
      id: id
    }
    makeDefaultCard(update)
      .unwrap()
      .then((res: any) => {
        if (res) {
          close();
          toast.success(res?.success || 'Card details updated successfully!');
        }
      })
      .catch((error: any) => {
        toast.error(error?.data?.message || 'Error while updated');
      })
  }

  const onSubmitData = (data: PaymentFormValues) => {
    const payload = {
      ...data,
      "card_type": 'Visa'
    }
    if (editCard) {
      let update = {
        data: payload,
        id: currectCard?._id
      }
      updateCard(update)
        .unwrap()
        .then((res: any) => {
          if (res) {
            close();
            toast.success(res?.success || 'Card details updated successfully!');
          }
        })
        .catch((error: any) => {
          toast.error(error?.error || 'Error while create');
        })
    } else {
      createCard(payload)
        .unwrap()
        .then((res: any) => {
          if (res) {
            close();
            toast.success(res?.success || 'Card details saved successfully!');
          }
        })
        .catch((error: any) => {
          toast.error(error?.data?.message || 'Error while create');
        })
    }



  }

  return (
    <>
      <Cards variant="secondary" classname="p-6" childClassName="flex-col">
        <form onSubmit={handleSubmit(handleSave)} className="grid grid-cols-1 divide-y-2">
          <div className="flex items-start justify-between pb-4">
            <div>
              <h4>Payment method</h4>
              <h5 className="font-normal">Update your billing details and address.</h5>
            </div>
          </div>

          <div className='py-4'>
            <div className="flex ">
              <div className="basis-5/12">
                <h5>Contact email</h5>
                <p className="font-normal">Where should invoices be sent?</p>
              </div>
              <div className="basis-7/12 w-full">
                <RadioButton
                  options={billingData.radioOptions}
                  control={control}
                  name="email"
                >
                  {(option) => (
                    <span className="flex flex-col">
                      {option.label}
                      {option.value === "isAccountEmail" && (
                        <p className="text-gray-700">{user?.email || "No email found"}</p>
                      )}
                      {option.value === "isAlternativeEmail" && (
                        <InputField
                          control={control}
                          className="pt-1"
                          placeholder="Enter your email"
                          type="email"
                          name="alternativeEmail_input"
                          rules={{
                            ...validationConstants.email,
                          }}
                        />
                      )}
                    </span>
                  )}
                </RadioButton>
                <button type="submit" className="bg-[#279964] text-[#FFFFFF] font-semibold mt-5 text-sm rounded-full h-10 pl-4 pr-4 hover:bg-[#1F7A50] transition-all duration-200"> Save Details</button>
              </div>
            </div>
          </div>

          <div className="flex flex-row py-4 ">
            <div className="basis-5/12">
              <h5>Card details</h5>
              <p className="font-normal">Select default payment method.</p>
            </div>
            <div className="space-y-4 py-4 w-full basis-7/12">
              {getCardData?.cards?.length > 0 && (
                getCardData?.cards?.map((card: any, index: number) => (
                  <Checkbox
                    className="flex flex-row-reverse justify-between"
                    control={control}
                    variant={"secondary"}
                    name={`paymentMethods[${index}].paymentMethodVisa`}
                  >
                    <div className="flex gap-3">
                      <VisaLogo />
                      <div>
                        <p className="text-gray-900">{card?.card_type} ending in {card?.card_number?.slice(-4)}</p>
                        <p className="text-gray-700">Expiry {card?.expiry_date}</p>
                        <div className="flex flex-row gap-3 pt-2.5">
                          {
                            card?.is_default ?
                              <button className="bg-[#279964] text-[#FFFFFF] font-semibold text-sm rounded-full h-10 pl-4 pr-4 hover:bg-[#1F7A50] transition-all duration-200"> Set as default</button>
                              :
                              <Button
                                type='text'
                                onClick={() => setAsDefault(card._id)}
                              >
                                Set as default
                              </Button>
                          }

                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => handleEditCard(card)}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Checkbox>
                ))
              )}
              <Button
                variant="text"
                color='primary'
                onClick={() => open()}
                icon={<Icons icon="heroicons-outline:plus" />}
              >
                Add Payment Method
              </Button>
            </div>
          </div>
        </form>
        <Drawer opened={opened} onClose={close} title="Edit Card Details" position='right' size={'lg'}>
          <PaymentForm onSubmitData={onSubmitData} isLoading={isLoading} onClose={close} edit={editCard} currentCard={currectCard} />
        </Drawer>

      </Cards >
    </>
  );
}
export default Billing