import { useEffect, useRef, useState, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import gsap from "gsap";
import { Dropdown } from 'antd';

import './HeaderNav.css';
import { OogwaiLogo2Full, SettingsIcon, HelpIcon, NotificationIcon, MenuIcon } from "../Icons/iconImages";

import { Tabs } from "./Tabs";
import { logOut } from "../../store/slice/userAuth";
import { useDispatch } from "react-redux";
import Icons from "../Icons";
import { auth } from "../../firebase/firebase";
import { Button } from "../Buttons";
import { useGetGeneralSettingsQuery } from "../../services/settingsSlice";

const sizes = {
  headingxs: "text-[0.75rem] font-semibold",
  textxs: "text-[0.75rem]font-semibold",
}

const HeaderNav = ({ children, className = "", size = "textxs", as, ...restProps }) => {
  const Component = as || "h6";
  return (
    <Component className={`${sizes[size]} ${className}`} {...restProps}>
      {children}
    </Component>
  )
}

const MainNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(location.pathname);

  const isNewLogin = location.pathname.includes('onboardingIntegration')

  const tabs = useMemo(() => ['Dashboard', 'Integrations'], []);
  const tabRefs = useRef([]);
  const highlightRef = useRef(null);

  const [user, setUser] = useState(null);

  const { data: userData } = useGetGeneralSettingsQuery('', {
    refetchOnMountOrArgChange: true,
  });
  const getUserData = userData?.settings;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser({
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Set the initial active tab based on the URL path
  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  // Animate the sliding pill effect
  useEffect(() => {
    const activeIndex = tabs.findIndex(tab => activeTab.includes(tab.toLowerCase()));
    const activeTabElement = tabRefs.current[activeIndex];

    if (activeTabElement && highlightRef.current) {
      gsap.to(highlightRef.current, {
        x: activeTabElement.offsetLeft,
        width: activeTabElement.offsetWidth,
        duration: 0.3,
        ease: 'power2.out',
      });
    }
  }, [activeTab, tabs]);


  const handleLogout = () => {
    dispatch(logOut());
    const auth = getAuth();
    signOut(auth);
    localStorage.removeItem("oogw.ai_user");
    navigate("/login")
  }

  const notificationItems = [
    {
      label: 'Notification 1',
      key: '1',
    },
    {
      label: 'Notification 2',
      key: '2',
    },
  ];
  const notificationProps = {
    items: notificationItems
  }

  const menuItems = [
    {
      label: (
        <div className="flex items-center space-x-2">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="relative">
              <img
                src={getUserData?.profile_picture || (getUserData?.first_name && getUserData?.last_name ? `https://eu.ui-avatars.com/api/?name=${getUserData?.first_name + " " + getUserData?.last_name}&size=250` : undefined)}
                alt=""
                className="w-12 h-12 rounded-full object-cover"
                loading="lazy"
              />
              <div className="absolute bottom-0.5 right-0.5 w-2.5 h-2.5 border border-white rounded-full bg-green-500 clip-cutout" />
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <span className="text-sm font-medium">
              {getUserData?.first_name || getUserData?.last_name ? `${getUserData?.first_name || ""} ${getUserData?.last_name || ""}` : "Name not found"}
            </span>
            <span className="text-sm font-normal">{getUserData?.email}</span>
          </div>
        </div>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Button
          icon={<Icons icon="heroicons-outline:user" className="w-4 h-4" />}
          type="link"
          size="small"
          className="ant-dropdown-menu-button text-gray-900 flex items-center justify-start space-x-2 w-full"
        >
          <span>View profile</span>
        </Button>
      ),
      key: '1',
    },
    {
      label: (
        <Button
          icon={<Icons icon="heroicons-outline:cog-8-tooth" className="w-4 h-4" />}
          type="link"
          size="small"
          className="ant-dropdown-menu-button text-gray-900 flex items-center justify-start space-x-2 w-full"
        >
          <span>Settings</span>
        </Button>
      ),
      key: '2',
    },
    {
      type: 'divider',
    },
    {
      label: (
        <Button
          icon={<Icons icon="heroicons:arrow-left-start-on-rectangle" className="w-4 h-4" />}
          type="link"
          size="small"
          onClick={handleLogout}
          className="ant-dropdown-menu-button text-gray-900 flex items-center space-x-2 justify-start w-full"
        >
          <span>Sign out</span>
        </Button>
      ),
      key: '3',
    },
  ]
  const menuProps = {
    items: menuItems,
  }


  return (
    <header className="fixed w-full z-50">
      <div className="main-header">
        <div className="header-logo">
          <Link to="/"><OogwaiLogo2Full className="h-[2rem]" /></Link>
        </div>
        {!isNewLogin && <div className="tabs flex justify-center">
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} tabRefs={tabRefs} />
        </div>}
        <div className="actions">
          {!isNewLogin ? (
            <div className="flex items-center relative">
              <Button
                className={`${activeTab.indexOf('/company-settings') !== -1 ? 'bg-green-100 text-green-600' : ''}`}
                type="text"
                icon={<SettingsIcon className={`w-4 h-4 ${activeTab.indexOf('/company-settings') !== -1 ? 'text-green-700' : 'text-gray-800'}`} />}
                iconPosition="left"
                onClick={() => navigate('/company-settings/general')}>Settings</Button>
              <Button
                type="text"
                icon={<HelpIcon className={`w-4 h-4 ${activeTab === '/help' ? 'text-green-700' : 'text-gray-800'}`} />}
                iconPosition="left"
                onClick={() => navigate('/support')}
              >
                Support
              </Button>
              <Dropdown className="" menu={notificationProps} trigger={['click']} placement="bottomRight">
                <Button type="text" icon={<NotificationIcon />} />
              </Dropdown>
              {/* ! FIXME */}
              <Dropdown className="" menu={menuProps} trigger={['click']} placement="bottomRight">
                <Button type="text" icon={<MenuIcon />} />
              </Dropdown>
            </div>
          ) : (
            <div>
              <Button
                type="text"
                iconPosition="left"
                onClick={() => navigate('/dashboard')}
              >
                Skip
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export { HeaderNav, MainNavbar };