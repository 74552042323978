import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { MainNavbar } from "../components/HeaderNav";
import { getAuthAdminAccess } from "../helper";

function PrivateRoute() {
  const location = useLocation();
  let isAuth = getAuthAdminAccess();

  return !isAuth ? (
    <Navigate to="/" />
  ) : (
    <>
      <MainNavbar />
      <div
        className={`content-wrapper pt-14 transition-all duration-150 ${location.pathname === "/onboardingIntegration" ? "bg-gray-100" : ""
          }`}
      >
        <div
          className={`page-min-height ${location.pathname === "/dashboard" ? "dashboard-wrapper" : "page-content"
            }`}
          key={location.pathname}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default PrivateRoute;
