import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Cards from '../../components/Cards';
import ToggleButton from '../../components/Toggle';
import { Button } from '../../components/Buttons';
import { useGetNotificationSettingsQuery, useUpdateNotificationSettingsMutation } from '../../services/settingsSlice';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';

function Notifications() {
  const {
    getValues,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    // defaultValues,
  });
  const [loading, setLoading] = useState(false);

  const [updateApi] = useUpdateNotificationSettingsMutation();

  const { data : getUserData} = useGetNotificationSettingsQuery('');


  useEffect(() => {
    if (getUserData) {
      setValue('feature_email', getUserData?.new_features?.email)
      setValue('feature_sms', getUserData?.new_features?.sms)
      setValue('integrations_email', getUserData?.integrations?.email)
      setValue('integrations_sms', getUserData?.integrations?.sms)
      setValue('account_email', getUserData?.account_related?.email)
      setValue('account_sms', getUserData?.account_related?.sms)
      setValue('activity_email', getUserData?.more_activity?.email)
      setValue('activity_sms', getUserData?.more_activity?.sms)
    }
  }, [getUserData])


  const handleSave = () => {
    setLoading(true)
    try {

      const values = getValues();

      let payload = {
        "new_features": {
            "email": values?.feature_email ?? false,
            "sms": values?.feature_sms ?? false
        },
        "integrations": {
            "email": values?.integrations_email ?? false,
            "sms": values?.integrations_sms ?? false
        },
        "account_related": {
            "email": values?.account_email ?? false,
            "sms": values?.account_sms ?? false
        },
        "more_activity": {
            "email": values?.activity_email ?? false,
            "sms": values?.activity_sms ?? false
        }
    }
       updateApi(payload)
      .unwrap()
      .then((response) => {
        setLoading(false)
        toast.success(response?.message ||'Settings updated successfully');
      })
      .catch((error) => {
        console.log("error", error)
        setLoading(false)

      })
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Cards variant='secondary' classname='p-6' childClassName={'flex-col'}>
      <div className="border-b border-solid border-gray-300 pb-4">
        <h4>Notification Settings</h4>
        <h5 className="font-normal">Update your photo and personal details here.</h5>
      </div>
      <form onSubmit={handleSubmit(handleSave)} className="py-4">
        <div className='flex w-full border-b border-solid border-gray-300 pb-4 gap-16'>
          <div className='basis-[35%]'>
            <h6 className='text-sm font-semibold text-[#535862]'>New Features</h6>
            <p className='text-sm text-[#535862]'>These are notifications for comments on your posts and replies to your comments.</p>
          </div>
          <div className='flex flex-col gap-3.5'>
            <ToggleButton control={control} label={'Email'} name={'feature_email'} />
            <ToggleButton control={control} label={'SMS'} name={'feature_sms'} />
          </div>
        </div>
        <div className='flex w-full border-b border-solid border-gray-300 py-4 gap-16'>
          <div className='basis-[35%]'>
            <h6 className='text-sm font-semibold text-[#535862]'>Integrations</h6>
            <p className='text-sm text-[#535862]'>These are notifications for when someone tags you in a comment, post or story.</p>
          </div>
          <div className='flex flex-col gap-3.5'>
            <ToggleButton control={control} label={'Email'} name={'integrations_email'} />
            <ToggleButton control={control} label={'SMS'} name={'integrations_sms'} />
          </div>
        </div>
        <div className='flex w-full border-b border-solid border-gray-300 py-4 gap-16'>
          <div className='basis-[35%]'>
            <h6 className='text-sm font-semibold text-[#535862]'>Account Related</h6>
            <p className='text-sm text-[#535862]'>These are notifications to remind you of updates you might have missed.</p>
          </div>
          <div className='flex flex-col gap-3.5'>
            <ToggleButton control={control} label={'Email'} name={'account_email'} />
            <ToggleButton control={control} label={'SMS'} name={'account_sms'} />
          </div>
        </div>
        <div className='flex w-full py-4 gap-16'>
          <div className='basis-[35%]'>
            <h6 className='text-sm font-semibold text-[#535862]'>More activity about you</h6>
            <p className='text-sm text-[#535862]'>These are notifications for posts on your profile, likes and other reactions to your posts, and more.</p>
          </div>
          <div className='flex flex-col gap-3.5'>
            <ToggleButton control={control} label={'Email'} name={'activity_email'} />
            <ToggleButton control={control} label={'SMS'} name={'activity_sms'} />
          </div>
        </div>
        <div className='flex justify-end gap-3 w-full col-start-2'>
          <Button
          // onClick={() => handleSignInWithGoogle()}
          // disabled={loading}
          // loading={loading}
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
          // disabled={loading}
          // loading={loading}
          onClick={handleSubmit(handleSave)}
          >
            Save changes
          </Button>
        </div>
      </form>
    </Cards>
  )
}

export default Notifications