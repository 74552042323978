// import './Sidebar.css';
import { Drawer, DrawerProps } from 'antd';
import { ReactNode } from 'react';

interface SidebarProps {
  open: boolean;
  onClose: () => void;
  header: ReactNode;
  className?: string;
  footer?: ReactNode;
  children: ReactNode;
  size?: DrawerProps['size'];
}

const Sidebar = ({ open, onClose, size = 'default', header, className, footer, children }: SidebarProps) => {
  return (
    <Drawer
      size={size}
      title={header}
      closeIcon={false}
      maskClosable 
      onClose={onClose}
      open={open}
      footer={footer}
      className={className}
      bodyStyle={{ padding: '0 1.5rem' }}
      headerStyle={{ border: 'none', paddingTop: '1.7rem' }}  
      footerStyle={{ border: 'none', paddingBottom: '1.7rem', paddingInline: '1.5rem' }}
    >
      {children}
    </Drawer>
  );
};


export default Sidebar;
