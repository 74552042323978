import { useState } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openModal = (modalName, data = null) => {
    setModalData(data ? { modalName, data } : modalName);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalData(null);
  };

  return {
    isOpen,
    openModal,
    closeModal,
    modalData,  // The data passed with the modal
  };
};

export default useModal;
