import { Collapse } from 'antd';
import React from 'react'
import { UseFormRegister } from 'react-hook-form';

import './styles.css';

interface AccordionProps {
  register: UseFormRegister<any>;
  className?: string;
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  expandIcon?: React.ReactNode[];
  expandIconPosition?: "start" | "end";
}

const Accordion: React.FC<AccordionProps> = ({ register, className = '', header, footer, children, expandIcon = [], expandIconPosition = 'end' }) => {
  return (
    <Collapse
      {...register}
      className={`shadow-sm rounded-2xl border border-solid border-[#e9e9eb] ${className}`}
      defaultActiveKey={['1']}
      bordered={false}
      expandIconPosition={expandIconPosition}
      expandIcon={({ isActive }) => isActive ? expandIcon[1] : expandIcon[0]}
    >
      <Collapse.Panel
        header={header}
        key="1"
      >
        {children}
        {footer}
      </Collapse.Panel>
    </Collapse>
  )
}

export default Accordion