import { Link, useLocation } from 'react-router-dom'
import Icons from '../Icons'

import './styles.css';
import { HomeIcon } from '../Icons/iconImages';

function Breadcrumbs() {
  const location = useLocation();

  const pathNames = location.pathname.split('/').filter(x => x);
  const pathNamesWithReadableNames = pathNames.map(name => {
    switch (name) {
      case 'company-settings':
        return 'Settings';
      case 'notifications':
        return 'Notifications';
      case 'security':
        return 'Security';
      case 'teams':
        return 'Teams';
      case 'plan':
        return 'Plan';
      case 'billing':
        return 'Billing'
      case 'general':
      default:
        return 'General';
    }
  });

  return (
    <nav aria-label="Breadcrumb" className='breadcrumbs'>
      <ol>
        <li key={'settings-icons'}>
          <div className="flex items-center">
            <p className="inline-flex items-center gap-1 text-md font-medium text-gray-700">
              <HomeIcon className={"h-[1.25rem] w-[1.25rem] text-text-600"} />
              <Icons icon="heroicons-outline:chevron-right" alt="settings-icon" className={"h-4 w-4 text-text-300"} />
            </p>
          </div>
        </li>
        <li key={'settings-label'}>
          <div className="flex items-center">
            <p className="inline-flex items-center gap-1 text-md font-medium text-gray-700 ">
              Settings
              <Icons icon="heroicons-outline:chevron-right" alt="settings-icon" className={"h-4 w-4 text-text-300"} />
            </p>
          </div>
        </li>
        {pathNamesWithReadableNames.map((name, index) => {
          const routeTo = `/${pathNames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathNamesWithReadableNames.length - 1;
          if (name === 'Settings') return;
          return (
            <li key={index}>
              <div className="flex items-center">
                <Link to={routeTo} className="inline-flex items-center text-md font-medium text-green-500 ">
                  {name}
                </Link>
                {!isLast && (
                  <Icons icon="heroicons-outline:chevron-right" alt="settings-icon" className={"h-[1.25rem] w-[1.25rem] text-text-600"} />
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs