import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../../firebase/firebase";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuth: false,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      const { user = null, token = null } = action.payload || {};
      state.user = user;
      state.token = token;
      state.isAuth = !!user && !!token;

      // Save to localStorage
      localStorage.setItem("token", token);
      // localStorage.setItem("user", JSON.stringify(user));
    },
    logOut: (state) => {
      state.user = null;
      state.isAuth = false;
      state.token = null;

      // Clear local storage
      localStorage.removeItem("token");
      // localStorage.removeItem("user");

      // Firebase logout
      auth.signOut().catch((error) => {
        console.error("Firebase sign-out error:", error);
      });
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const { setUser, logOut, setLoading, setError, clearError } = authSlice.actions;
export default authSlice.reducer;
