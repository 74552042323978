// Checkbox.js
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import './styles.css';
import { CheckIcon } from '../Icons/iconImages';
import React from 'react';

interface CheckboxProps {
  name: string;
  control: Control<FieldValues>;
  label?: string;
  rules?: RegisterOptions;
  onClick?: () => void;
  className?: string;
  variant?: 'primary' | 'secondary';
  desc?: string;
  children?: React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  control,
  label,
  rules,
  onClick,
  className,
  variant = 'primary',
  desc,
  children,
}) => {
  const variants = {
    primary: 'items-center',
    secondary:
      'border-2 border-solid p-4 rounded-xl bg-white height transition-all duration-0 ease-in-out',
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { onChange, value } }) => (
        <label
          className={`gap-3 ${variants[variant]} ${
            variant === 'secondary' && value ? 'border-[#26BF77]' : 'border-gray-400'
          } flex cursor-pointer transition-all duration-200 ease-in-out ${className || ''}`}
          onClick={(e) => {
            e.stopPropagation(); // Prevent label click propagation
            onChange(!value);
          }}
        >
          {/* Checkbox container */}
          <div
            className={`w-5 h-5 flex justify-center items-center border rounded-md ${
              value ? 'border-[#26BF77]' : 'border-gray-400 bg-white'
            } ${value ? 'bg-[#26BF77]' : ''} transition-all`}
          >
            {/* Custom icon */}
            {value && (
              <CheckIcon
                className={`transition-all duration-200 ease-in-out bg-[#26BF77] w-3 h-3 rounded-sm ${
                  value ? 'text-white' : ''
                }`}
              />
            )}
          </div>
          {/* Label */}
          <div className="flex flex-col">
            <span className="text-sm font-medium text-[#414651] select-none">{label}</span>
            {desc && (
              <span className="text-sm font-normal text-[#414651] select-none pt-2">
                {desc}
              </span>
            )}
            {/* Children (e.g., buttons) */}
            <div
              onClick={(e) => {
                e.stopPropagation(); // Ensure child clicks do not toggle the checkbox
              }}
            >
              {React.Children.map(children, (child) =>
                React.isValidElement(child)
                  ? React.cloneElement(child as React.ReactElement, {
                      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation(); // Prevent checkbox toggle
                        child.props.onClick?.(e); // Call child's original onClick, if any
                      },
                    })
                  : child
              )}
            </div>
          </div>
        </label>
      )}
    />
  );
};

export default Checkbox;
