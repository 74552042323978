import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './HeaderNav.css';
import { HeaderNav } from '.';
import { Button } from '../Buttons';
import { Segmented } from 'antd';
import { Control, Controller } from 'react-hook-form';

interface TabsProps {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  tabRefs: any;
  type?: "Link" | "Button";
}


function Tabs({ tabs, activeTab, setActiveTab, tabRefs, type = "Link" }: TabsProps) {
  switch (type) {
    case "Button":
      return (
        <ul>
          {
            tabs.map((tab, index) => {
              return (
                <li key={index} ref={(el) => (tabRefs.current[index] = el)}>
                  <Button
                    // variant="link"
                    className={activeTab === `/${tab.toLowerCase()}` ? "activeTab" : ""}
                    onClick={() => setActiveTab(`/${tab.toLowerCase()}`)}
                  >
                    <HeaderNav as={"h1"} size="headingxs">{tab}</HeaderNav>
                  </Button>
                </li>
              );
            })
          }
        </ul>
      )
    default:
    case "Link":
      return (
        <ul>
          {
            tabs.map((tab, index) => {
              return (
                <li key={index} ref={(el) => (tabRefs.current[index] = el)}>
                  <Link
                    to={`/${tab.toLowerCase()}`}
                    className={activeTab.includes(tab.toLowerCase()) ? "activeTab" : ""}
                    onClick={() => setActiveTab(`/${tab.toLowerCase()}`)}
                  >
                    <HeaderNav as={"h1"} size="headingxs">{tab}</HeaderNav>
                  </Link>
                </li>
              );
            })
          }
        </ul>
      )
  }
}

interface TabSliderProps {
  tabs: Array<{
    value: string,
    label: string,
    message?: string,
    component: React.ReactNode,
    footerContent?: string,
    footerLink?: React.ReactNode | string,
    footerLabel?: string
  }>;
  activeTab: string;
  setActiveTab: (tab: React.ReactNode | string) => void;
  title?: string;
}

function TabSlider({ tabs = [], activeTab, setActiveTab, title, }: TabSliderProps) {
  const [height, setHeight] = useState("auto");
  const contentRef = useRef<HTMLDivElement | null>(null);

  // Update height dynamically when active tab or its content changes
  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      }
    };

    // Update height on mount and whenever the active content changes
    updateHeight();

    // Listen for mutations to dynamically adjust height when content updates
    const observer = new MutationObserver(updateHeight);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, [activeTab]);

  return (
    <>
      {title && <p className='text-sm font-normal text-gray-700'>{title}</p>}
      {tabs?.find(tab => tab.value === activeTab)?.message && <p className='text-[#535862] mb-5 text-center'>{tabs?.find(tab => tab.value === activeTab)?.message}</p>}
      <div className="w-full max-w-md mx-auto" style={{ marginTop: '0.25rem' }}>
        {/* ? Tabs Header */}
        <div className="relative flex bg-gray-200 rounded-full p-1">
          {tabs.map((tab) => (
            <button
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              className={`flex-1 text-center text-nowrap py-2 rounded-full text-sm font-semibold z-10 transition-colors ${activeTab === tab.value ? "text-[#414651]" : "text-gray-600"
                }`}
            >
              {tab.label}
            </button>
          ))}
          {/* Sliding Pill */}
          <div
            className="absolute top-0 left-0 h-full bg-white border border-[#D5D7DA] rounded-full transition-transform duration-300"
            style={{
              width: `${100 / tabs.length}%`,
              transform: `translateX(${tabs.findIndex(tab => tab.value === activeTab) * 100}%)`,
            }}
          ></div>
        </div>

        {/* ? Content Container with Height Animation */}
        {tabs.find(tab => tab.value === activeTab)?.component && (
          <div
            className="relative mt-4 overflow-hidden transition-all duration-300"
            style={{ height }}
          >
            <div ref={contentRef}>
              {tabs.find(tab => tab.value === activeTab)?.component}
              <p className="text-sm text-center font-light text-gray-500 dark:text-gray-400">
                {tabs.find(tab => tab.value === activeTab)?.footerContent}<span onClick={() => setActiveTab(tabs.find(tab => tab.value === activeTab)?.footerLink)} className="font-medium text-primary-600 cursor-pointer"> {tabs.find(tab => tab.value === activeTab)?.footerLabel}</span>
              </p>
            </div>
          </div>)
        }
      </div>
    </>
  );
}

interface CustomSegmentedProps {
  label?: string;
  onChange?: (value: any) => void;
  value?: any;
  name?: string;
  options: any;
  control?: Control<any>;
  rest?: any;
}

const CustomSegmented = ({
  label,
  onChange,
  value,
  name = '',
  options,
  control,
  ...rest
}: CustomSegmentedProps) => {
  console.log('options', rest)
  return (
    <div>
      {label && <p className="text-sm font-medium text-gray-700 pb-1">{label}</p>}
      <div className="overflow-x-auto rounded-full bg-gray-200 border border-gray-300">
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error: fieldError } }) => (
            <Segmented
              options={options}
              onChange={onChange}
              value={value}
              style={{ width: '100%' }} // Check if `style` is supported
              {...rest}
            />
          )}
        />
      </div>
    </div>
  )
};


export { Tabs, TabSlider, CustomSegmented }