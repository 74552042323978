// Import React and GSAP
import { useEffect, useState } from 'react';
import { SvgLoaderOne, SvgLoaderThree, SvgLoaderTwo } from './Icons/iconImages';

// Import SVGs or use inline SVGs directly

const AnimatedSVG = ({className}) => {
  const frames = [<SvgLoaderOne />, <SvgLoaderTwo />, <SvgLoaderThree />]; // Array of frames
  const [currentFrame, setCurrentFrame] = useState(0);

  useEffect(() => {
    // Interval to cycle through frames
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => (prevFrame + 1) % frames.length);
    }, 150); // Adjust timing as needed (200ms here)

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [frames.length]);

  return (
    <div className={className}>
      {frames[currentFrame]}
    </div>
  );
};


export default AnimatedSVG;
