import { baseApi } from "./baseQuery";

const dashboardApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    // ask-google-analytics-question
    askGoogleAnalyticsQuestion: build.query({
      query: (payload) => {
        return {
          url: `/api/v1/ask-google-anaytics-question?question=${payload.question}&property_id=${payload.property_id}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useLazyAskGoogleAnalyticsQuestionQuery,
} = dashboardApi;