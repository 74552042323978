import React from 'react'
import DrawerNav from '../../components/DrawerNav'
import { Outlet, useLocation } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import Icons from '../../components/Icons'
import { HomeIcon } from '../../components/Icons/iconImages'
import Breadcrumbs from '../../components/Breadcrumbs'

function CompanySettings() {
  const location = useLocation();

  const pathNames = location.pathname.split('/').filter(x => x);
  const pathNamesWithReadableNames = pathNames.map(name => {
    switch (name) {
      case 'company-settings':
        return 'Settings';
      case 'notifications':
        return 'Notifications';
      case 'security':
        return 'Security';
      case 'teams':
        return 'Teams';
      case 'plan':
        return 'Plan';
      case 'billing':
        return 'Billing'
      case 'general':
      default:
        return 'General';
    }
  });
  return (
    <div className='space-y-5'>
      <div className='grid grid-cols-12'>
        <div className='col-span-2'></div>
        <div className='col-span-10 pl-5'>
          <Breadcrumbs />
          {/* <Breadcrumb
            separator=">"
            items={[
              {
                title: <HomeIcon className={"h-[1.25rem] w-[1.25rem] text-text-600"} />,
              },
              ...pathNamesWithReadableNames.map((name, index) => ({
                title: (
                  <>
                    <span className={index === pathNamesWithReadableNames.length - 1 ? 'text-green-500 font-semibold' : 'text-text-600 font-semibold'}>{name}</span>
                  </>
                ),
              })),
            ]}
          /> */}
        </div>
      </div>

      <div className='grid grid-cols-12'>
        <div className='col-span-2'><DrawerNav /></div>
        <div className='col-span-10 pl-5'><Outlet /></div>
      </div>
    </div>
  )
}

export default CompanySettings
