import gsap from 'gsap';
import React from 'react'

function ProgressBar({ progress = 0, totalValue = 100, animate = false }) {
  const progressRef = React.useRef(null);
  const animation = React.useRef(null);

  React.useEffect(() => {
    if (animate) {
      animation.current = gsap.to(progressRef.current, {
        width: `${progress}%`,
        duration: 1,
        ease: "power2.inOut",
      });
    } else {
      progressRef.current.style.width = `${progress}%`;
    }
    return () => {
      if (animate) {
        animation.current.kill();
      }
    };
  }, [animate, progress]);

  return (
    <div className="w-full">
      <div className="text-sm font-medium text-gray-950 pb-2">
        {`${Math.round(progress)} out of ${totalValue} users`}
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div
          ref={progressRef}
          className="bg-green-600 h-2 rounded-full"
          style={{ width: "0%" }}
        ></div>
      </div>
    </div>
  );
}


export default ProgressBar;
