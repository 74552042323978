import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cards: [
    { id: 1, cardNumber: '5105 1051 0510 5100', cardholderName: 'John Doe', expiryDate: '12/24', type: 'Mastercard', cvv: '123', isDefault: true },
    { id: 2, cardNumber: '4111 1111 1111 1111', cardholderName: 'Jane Doe', expiryDate: '06/25', type: 'Visa', cvv: '321' },
    { id: 3, cardNumber: '5105 1051 0510 5100', cardholderName: 'Bob Doe', expiryDate: '09/26', type: 'Mastercard', cvv: '456' },
  ],
};

const paymentCard = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    addCard: (state, action) => {
      state.cards.push(action.payload);
    },
    editCard: (state, action) => {
      const index = state.cards.findIndex(card => card.id === action.payload.id);
      if (index !== -1) {
        state.cards[index] = action.payload;
        if (action.payload.isDefault) {
          state.cards.forEach(card => {
            if (card.id !== action.payload.id) {
              card.isDefault = false;
            }
          });
        }
      }
    },
    deleteCard: (state, action) => {
      state.cards = state.cards.filter(card => card.id !== action.payload);
    },
  },
});


export const { addCard, editCard, deleteCard } = paymentCard.actions;
export default paymentCard.reducer;