import React, { forwardRef } from "react";
import { Button as AntButton } from "antd";
import Icons from "../Icons";
import "./buttons.css";
import { BaseButtonProps } from "antd/es/button/button";

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  variant?: "primary" | "secondary" | "ghost" | "bordered" | "green" | "greenBordered" | "danger" | "redBordered" | "mossSolid" | "mossOutline" | "disabledSolid";
  size?: "sm" | "md" | "lg";
  name?: string;
  handleSwitch?: (currentIconState: boolean) => void;
  iconSwitch?: [string, string];
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((
  {
    type = "button",
    className = "",
    children,
    onClick,
    disabled,
    variant = "primary",
    size = "md",
    name,
    handleSwitch,
    iconSwitch,
    ...props
  },
  ref
) => {
  const [icon, setIcon] = React.useState(false);

  const variants: any = {
    primary: "button-primary border-2 border-solid shadow-sm border-white border-opacity-10",
    bordered: "button-bordered border-2 border-solid shadow-sm border-opacity-40 border-[#d5d6d9] hover:bg-[#d5d6d9] text-[#414651]",
    ghost: "button-ghost border-2 border-solid border-white border-opacity-10",
    green: "button-primary border-2 border-solid shadow-sm border-white border-opacity-10",
    borderedGreen: "button-bordered border-2 border-solid shadow-sm border-[#26BF77] text-[#26BF77] hover:bg-moss-50",
    danger: "bg-red-500 hover:bg-red-600 text-white",
  };

  const iconVariants: any = {
    primary: "",
    bordered: "",
    ghost: "text-gray-800 rounded-full",
    danger: "bg-red-500 hover:bg-red-600 text-white",
  };

  const handleSubmit = () => {
    if (handleSwitch) {
      handleSwitch(icon);
      setIcon(!icon);
    }
  };

  return (
    <button
      aria-label="Menu"
      className={`flex overflow-hidden justify-center items-center px-2 w-auto h-10 rounded-full ${variants[variant]} ${className}`}
      ref={ref}
      onClick={handleSubmit}
      type={type}
      disabled={disabled}
      {...props}
    >
      {iconSwitch ? (
        <Icons
          icon={icon ? iconSwitch[0] : iconSwitch[1]}
          // alt="icon-alt"
          className={`h-[1.25rem] w-[1.25rem] flex-shrink-0 ${iconVariants[variant]}`}
        />
      ) : (
        <Icons
          icon={name || ""}
          // alt="icon-alt"
          className={`h-[1.25rem] w-[1.25rem] flex-shrink-0 ${iconVariants[variant]}`}
        />
      )}
    </button>
  );
});

interface ButtonProps extends BaseButtonProps {
  rest?: any;
  onClick?: (e: any) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  htmlType?: "button" | "submit" | "reset";
}

const Button = ({ type = 'default', ...rest }: ButtonProps) => {
  return (
    <AntButton htmlType="button" type={type} {...rest}>{rest.children}</AntButton>
  )
};

export { IconButton, Button };
