import { baseApi } from "./baseQuery";

const integrationApi = baseApi.injectEndpoints({
  endpoints: (build) => ({

    // GOOGLE INTEGRATION
    integration: build.query({
      query: (body) => {
        return {
          url: `api/v1/oauth?basepath=${body.basepath}`,
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${body.token}`,
          },
        }
      },
    }),

    verifyGoogleToken: build.query({
      query: (code) => {
        return {
          url: `/api/v1/google/verify?code=${code}`,
          method: "GET",
        }
      },
    }),

    getAnalyticStatus: build.query({
      query: () => {
        return {
          url: `/api/v1/google/analytics-status`,
          method: "GET",
        }
      },
      providesTags: ['metaIntegration']
    }),

    removeGA: build.query({
      query: () => {
        return {
          url: `/api/v1/google/remove`,
          method: "GET",
        }
      },
    }),

    listAnalytics: build.query({
      query: () => {
        return {
          url: `/api/v1/list-analytics-accounts`,
          method: "GET",
        }
      },
    }),

    getAnalyticsData: build.query({
      query: (payload) => {
        return {
          url: `api/v1/fetch-data-from-bigquery?property_id=${payload?.property_id}`,
          method: "GET",
          // body: { property_id: payload?.property_id },
        }
      },
    }),

    downloadAnalyticsData: build.query({
      query: (payload) => {
        return {
          url: `api/v1/ga-data-excel-download?property_id=${payload?.property_id}`,
          method: "GET",
          responseHandler: ((response) => response.blob()),
          // body: { property_id: payload?.property_id },
        }
      },
    }),

    // SHOPIFY INTEGRATION
    shopifyIntegration: build.query({
      query: (payload) => {
        return {
          url: `/api/v1/shopify/auth/start?shop=${payload?.data}&basepath=${payload?.basepath}`,
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${payload?.token}`,
          },
        }
      },
    }),

    verifyShopify: build.query({
      query: (body) => {
        return {
          url: `api/v1/shopify/auth?code=${body.code}&hmac=${body.hmac}&host=YWRtaW4uc2hvcGlmeS5jb20vc3RvcmUvcXVpY2tzdGFydC05MDFmMmNjYg&shop=${body.shop}&state${body.state}&basePath=${body.basePath}`,
          method: "GET",
        }
      },
    }),

    removeShopify: build.query({
      query: () => {
        return {
          url: `/api/v1/shopify/remove`,
          method: "GET",
        }
      }
    }),

    getShopifyData: build.query({
      query: () => {
        return {
          url: `/api/v1/shopify/data/`,
          method: "GET",
          // headers: {
          //   'Content-Type': 'application/json',
          //   'Authorization': `Bearer ${payload?.token}`,
          // },
        }
      },
    }),

    // META INTEGRATION
    metaIntegration: build.query({
      query: () => {
        return {
          url: `api/v1/meta/auth/start`,
          method: "GET",
        }
      },
    }),

    metaVerify: build.mutation({
      query: (code) => {
        return {
          url: `api/v1/meta/verify?code=${code}`,
          method: "POST",
        }
      },
      invalidatesTags: ['metaIntegration']
    }),

    removeMeta: build.query({
      query: () => {
        return {
          url: `api/v1/meta/remove/`,
          method: "GET",
        }
      }
    }),

    getMetaData: build.query({
      query: () => {
        return {
          url: `/api/v1/meta/data/`,
          method: "GET",
          // headers: {
          //   'Content-Type': 'application/json',
          //   'Authorization': `Bearer ${payload?.token}`,
          // },
        }
      },
    }),

    // LIST ANALYTICS ACCOUNTS
    getListAnalyticsAccounts: build.query({
      query: () => {
        return {
          url: `/api/v1/list-property-id-dropdown`,
          method: "GET",
        }
      },
    }),

    // GOOGLE ADS
    googleAds: build.query({
      query: (body) => {
        return {
          url: `api/v1/googleAds/oauth`,
          method: "GET",
        }
      },
    }),

    verifyGoogleAdsToken: build.query({
      query: (code) => {
        return {
          url: `/api/v1/googleAds/verify?code=${code}`,
          method: "GET",
        }
      },
    }),

    removeGoogleAds: build.query({
      query: () => {
        return {
          url: `/api/v1/googleAds/remove/`,
          method: "GET",
        }
      }
    }),

  }),

  // @ts-ignore
  overrideExisting: module.hot?.status() === "apply",
});

export const {
  useLazyIntegrationQuery,
  useLazyVerifyGoogleTokenQuery,
  useGetAnalyticStatusQuery,
  useLazyRemoveGAQuery,
  useLazyListAnalyticsQuery,
  useGetAnalyticsDataQuery,
  useLazyDownloadAnalyticsDataQuery,
  // SHOPIFY
  useLazyShopifyIntegrationQuery,
  useLazyVerifyShopifyQuery,
  useGetShopifyDataQuery,
  useLazyRemoveShopifyQuery,
  // META
  useLazyMetaIntegrationQuery,
  useMetaVerifyMutation,
  useGetMetaDataQuery,
  useLazyRemoveMetaQuery,
  // get property id
  useGetListAnalyticsAccountsQuery,
  // GOOGLE ADS
  useLazyGoogleAdsQuery,
  useLazyVerifyGoogleAdsTokenQuery,
  useLazyRemoveGoogleAdsQuery,
} = integrationApi;
