import React from 'react';
import { Controller } from 'react-hook-form';
import './styles.css';

interface RadioOption {
  label: string;
  value: string;
}

interface RadioProps {
  name: string;
  options: RadioOption[];
  children?: (option: RadioOption) => React.ReactNode;
  control: any;
  defaultValue?: string;
}

const RadioButton = ({ name, options, children, control, defaultValue } : RadioProps) => {
  const defaultOption = defaultValue
    ? options.find((option) => option.value === defaultValue)
    : options[0];
  if (!defaultOption) {
    throw new Error(
      `No default value found for radio buttons with name '${name}'.`
    );
  }
  return (
    <div className="flex flex-col space-y-2">
      {options.map((option) => (
        <Controller
          key={option.value}
          name={name}
          control={control}
          defaultValue={defaultOption.value}
          render={({ field }) => (
            <label className="flex items-start gap-x-2 cursor-pointer">
              <input
                {...field}
                type="radio"
                value={option.value}
                className="hidden"
              />
              {/* Custom Radio Button */}
              <span
                className={`w-4 h-4 rounded-full border-2 ${
                  field.value === option.value ? 'bg-green-500 border-green-500' : 'border-green-500'
                } flex items-center justify-center transition-all`}
              >
                {/* White Dot when selected */}
                <span
                  className={`w-1.5 h-1.5 rounded-full bg-white transition-all ${
                    field.value === option.value ? 'block' : 'hidden'
                  }`}
                />
              </span>
              <span className="text-sm w-full">{children ? children(option) : option.label}</span>
            </label>
          )}
        />
      ))}
    </div>
  );
};


export default RadioButton;
