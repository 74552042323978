import React, { ReactNode } from 'react';
import './styled.css';
import AnimatedSVG from '../Loader';
import { Control, Controller, FieldError } from 'react-hook-form';
import Icons from '../Icons';
import { IconButton } from '../Buttons';
import { Input } from 'antd';

interface InputFieldProps {
  control: Control<any>;
  error?: FieldError | string;
  label?: string | React.ReactElement;
  rules?: {
    required?: any;
    [key: string]: any;
  };
  placeholder?: string;
  hint?: string;
  name: string;
  type?: string;
  icon?: ReactNode;
  leftIcon?: ReactNode;
  hidePasswordIcon?: boolean;
  iconDirection?: 'left' | 'right' | 'insideLeft' | 'insideRight';
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  customLoadingIcon?: ReactNode;
  isLoading?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<InputFieldProps & React.ComponentProps<typeof Input>> = ({
  control,
  error,
  label,
  rules,
  placeholder = '',
  hint,
  name,
  type = 'text',
  icon,
  leftIcon,
  hidePasswordIcon,
  iconDirection,
  onClick,
  className = '',
  disabled,
  defaultValue,
  customLoadingIcon,
  isLoading,
  onKeyPress,
  onChange,
}) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const handleOnClick = () => {
    onClick?.();
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e); 
  }

  return (
    <div className={`flex flex-col w-full ${className}`}>
      <div className="relative flex flex-col w-full text-sm tracking-tight leading-5 text-gray-500">
        <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field, fieldState: { error: fieldError } }) => (
            <>
              {(label || rules?.required) && (
                <span className='mb-1'>
                  {label && (
                    <label htmlFor={"input" + name} className="text-sm font-medium text-[#414651]">
                      {label}
                    </label>
                  )}
                  {rules?.required && <span className="pl-1 text-green-500">*</span>}
                </span>
              )}
              <Input
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  handleOnChange(e);
                }}
                id={"input" + name}
                type={passwordVisible ? 'text' : type}
                className='rounded-full border border-gray-400 w-full px-4 py-2.5 tracking-tight leading-5 text-gray-900 shadow-sm'
                placeholder={placeholder}
                suffix={type === 'password' ? <Icons
                  icon={passwordVisible ? "heroicons-outline:eye" : "heroicons-outline:eye-off"}
                  className="h-[1.1rem] w-[1.1rem] flex-shrink-0 cursor-pointer hover:text-gray-800 transition-all"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                /> : iconDirection === 'right' && (isLoading && customLoadingIcon ? customLoadingIcon : icon)}
                prefix={iconDirection === 'left' && icon}
                disabled={disabled}
                defaultValue={defaultValue}
                onPressEnter={onKeyPress}
                status={fieldError ? 'error' : undefined}
                
              />
              {(fieldError || error) && <p className="text-xs tracking-tight leading-5 text-red-500">{typeof error === 'string' ? error : (fieldError || error)?.message}</p>}
            </>
          )}
        />
        {hint && <p className="mt-1.5 text-xs tracking-tight leading-5 text-gray-600">{hint}</p>}
      </div>
    </div>

  );
}

export default InputField;