import React from 'react'
import { GoogleLogo } from '../../components/Icons/iconImages'
import InputField from '../../components/InputField'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Buttons';
import { googleSignUpThunk } from '../../store/thunk/userAuthThunk';
import { useDispatch } from 'react-redux';
import Icons from '../../components/Icons';
import { validationConstants } from '../../constants/validations';
import { createUserWithEmailAndPassword } from '@firebase/auth';
import { useRegisterMutation } from '../../services/authSlice';
import { auth } from '../../firebase/firebase';


function SignUpScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUp] = useRegisterMutation();


  const defaultValues = {
    name: '',
    email: '',
    password: '',
  };
  const {
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleSignUp = async () => {
    const { email, password, fullName } = getValues();
    try {
      setLoading(true);
      const user = await createUserWithEmailAndPassword(auth, email, password);
      const token = await user.user.getIdToken();

      const body = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
      const payload = {
        body: body,
        data: { email, password, fullName },
      }
      signUp(payload)
        .unwrap()
        .then((response) => {
          console.log("response", response);
          setLoading(false);
          if (response) {
            navigate('/onboardingIntegration');
            localStorage.setItem("oogw.ai_user", token)
          }
        })
        .catch((error) => {
          console.log("error", error);
          setError(error?.data?.message || 'An unexpected error occurred');
          setLoading(false);
        })
    } catch (err) {
      setLoading(false);
      if (err?.code) {
        switch (err?.code) {
          case "auth/email-already-in-use":
            return setError("Email already in use.");
          case "auth/invalid-email":
            return setError("Invalid email format.");
          case "auth/weak-password":
            return setError("Password is too weak.");
          case "auth/missing-password":
            return setError("Please enter a password.");
          default:
            return setError(`Firebase error: ${err?.message}`);
        }
      }

    } finally {
      setLoading(false);
    }
  }

  const signInWithGoogle = async () => {
    try {
      setLoading(true);
      const res = await dispatch(googleSignUpThunk());
      if (res) {
        throw new Error(res);
      }
      setLoading(false);
      navigate('/dashboard');
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  const handleSubmitError = (data) => {
    // console.log('error triggered', data)
  }

  // useEffect(() => {
  //   if (error) {
  //     const timer = setTimeout(() => setError(''), 5000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [error]);

  return (
    <div className="md:space-y-4 z-10 mb-3">
      <form onSubmit={handleSubmit(handleSignUp, handleSubmitError)} className="space-y-5 md:space-y-5">
        <InputField
          control={control}
          label={'Name'}
          placeholder={'Enter your full name'}
          name="fullName"
          rules={{
            required: 'Name is required',
            ...validationConstants.name,
          }}
          error={errors.fullName}
        />
        <InputField
          control={control}
          label={'Email'}
          placeholder={'Enter your email'}
          type='email'
          name="email"
          rules={{
            required: 'Email is required',
            ...validationConstants.email,
          }}
          error={errors.email}
        />
        <InputField
          control={control}
          label={'Password'}
          placeholder={'Enter your password'}
          name="password"
          type='password'
          rules={{
            required: 'Password is required',
            ...validationConstants.password,
          }}
          error={errors.password}
        />
        {error && (
          <div className="flex items-center justify-between w-full border-t border-gray-200 dark:border-gray-600 pt-4">
            <p className='text-red-500 text-sm'>{error}</p>
            <Icons icon={"heroicons-outline:x-circle"} className="max-h-5 max-w-5 h-5 w-5 text-red-500 cursor-pointer" onClick={() => setError(null)}>Close</Icons>
          </div>
        )}
        <Button
          type='primary'
          className="w-full"
          disabled={loading}
          loading={loading}
          // onClick={() => handleSignUp()}
          htmlType="submit"

        >
          Get Started
        </Button>
        <Button
          variant="text"
          className="w-full"
          onClick={() => signInWithGoogle()}
          disabled={loading}
          loading={false}
          size={'md'}
          icon={<GoogleLogo className='w-5 h-5' />}
        >
          Sign up with Google
        </Button>
      </form>
    </div>
  )
}

export default SignUpScreen