import { doc, setDoc } from "@firebase/firestore";
import { auth, db } from "../../firebase/firebase";
import { setLoading, setUser } from "../slice/userAuth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const googleLoginThunk = () => {
  return async (dispatch) => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDoc = doc(db, "users", user.uid);
      await setDoc(userDoc, {
        uid: user.uid,
        email: user.email,
        fullName: user.displayName,
      });
      dispatch(setUser({ uid: user.uid, email: user.email, name: user.displayName }));
    } catch (error) {
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          return 'This account already exists with different sign-in credentials.';
        case 'auth/auth-domain-config-required':
          return 'Please provide an auth domain configuration.';
        case 'auth/cancelled-popup-request':
          return 'Popup request was cancelled.';
        case 'auth/operation-not-allowed':
          return 'Operation is not allowed.';
        case 'auth/popup-blocked':
          return 'Popup has been blocked.';
        case 'auth/popup-closed-by-user':
          return 'Popup has been closed by the user.';
        case 'auth/unauthorized-domain':
          return 'Domain is not authorized.';
        case 'auth/user-cancelled':
          return 'IdP denied access. User refused to grant permission.';
        default:
          return `An unexpected error occurred: ${error.message}`;
      }
    }
  };
};

export const googleSignUpThunk = () => {
  return async (dispatch) => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      dispatch(setUser({ uid: user.uid, email: user.email, name: user.displayName }));
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          return 'Email already in use.';
        case 'auth/invalid-email':
          return 'Invalid email format.';
        case 'auth/operation-not-allowed':
          return 'Operation is not allowed.';
        case 'auth/weak-password':
          return 'Password is too weak.';
        case 'auth/popup-closed-by-user':
          return 'Popup has been closed by the user.';
        case 'auth/user-cancelled':
          return 'IdP denied access. User refused to grant permission.';
        default:
          return `An unexpected error occurred: ${error.message}`;
      }
    }
  };
};

export const signUpThunk = async (email, password, name) => {
    try {
      // Attempt to create the user in Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      // Ensure a valid user object before proceeding
      if (!user) {
        throw new Error('User creation failed.');
      }
  
      // Get the ID token from Firebase
      const idToken = await user.getIdToken();
  
      // Prepare the request configuration for the backend
      const config = {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${idToken}`,
        },
      };
  
      // Send the user data to your backend
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/register`,
        { email, password, fullname: name },
        config
      );
  
      const userFromBackend = response.data;
  
      // // Dispatch the user data to the Redux store
      // dispatch(
      //   setUser({
      //     uid: userFromBackend._id,
      //     email: userFromBackend.email,
      //     name: userFromBackend.name,
      //   })
      // );
    } catch (error) {
      console.error("Sign-Up Error:", error);
      return error;
      // Firebase Error Handling
      // if (error.code) {
      //   switch (error.code) {
      //     case "auth/email-already-in-use":
      //       return rejectWithValue("Email already in use.");
      //     case "auth/invalid-email":
      //       return rejectWithValue("Invalid email format.");
      //     case "auth/weak-password":
      //       return rejectWithValue("Password is too weak.");
      //     case "auth/missing-password":
      //       return rejectWithValue("Please enter a password.");
      //     default:
      //       return rejectWithValue(`Firebase error: ${error.message}`);
      //   }
      // }

      // Backend Error Handling
  //     if (error.data) {
  //       return rejectWithValue(error.data.message || "Backend API error.");
  //     }

  //     return rejectWithValue("An unexpected error occurred.");
  }
}


// ? INTEGRATION THUNK
export const oauthIntegrationThunk = (email, password, name) => async (dispatch) => {
  try {
    // // Attempt to create the user in Firebase
    // const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // const user = userCredential.user;
    // // Ensure a valid user object before proceeding
    // if (!user) {
    //   throw new Error('User creation failed.');
    // }

    // Get the ID token from Firebase
    // const idToken = await user.getIdToken();

    // Prepare the request configuration for the backend
    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${idToken}`,
        // 'X-CSRFToken': csrfToken, // Include CSRF token in headers
      },
    };

    // Send the user data to your backend
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/v1/oauth`,
      // { email, password },
      // config
    ).catch((error) => {
      console.log(error);
    });

    const userFromBackend = response.data;

    // Dispatch the user data to the Redux store
    dispatch(
      setUser({
        uid: userFromBackend._id,
        email: userFromBackend.email,
        name: userFromBackend.name,
      })
    );

  } catch (error) {
    // Handle other errors (e.g., backend or network errors)
    if (error.response) {
      // Handle backend response errors
      return `Backend error: ${error.response.data.message}`;
    }

    // General fallback for unexpected errors
    return `An unexpected error occurred: ${error.message}`;
  }
}