import React from 'react'
import Icons from '../Icons'

interface ListsProps {
  items: { label: string, description?: string }[];
  title: string;
  description: string;
}

function Lists({ items, title, description }: ListsProps) {
  return (
    <>
      <h4 className="text-gray-900">{title}</h4>
      <p className="description">{description}</p>
      <ul className="max-w-md space-y-4 py-4 text-gray-500 list-inside">
        {items.map((item, index) => (
          <li className="flex flex-row items-start gap-2" key={index}>
            <Icons icon={'heroicons-outline:check-circle'} className="min-w-6 h-auto text-green-600" />
            <div>
              <p className='text-gray-950'>{item.label}</p>
              {item.description && <p className="text-sm text-gray-800">{item.description}</p>}
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Lists;