import React from 'react'

interface BadgeProps {
  title?: string
  className?: string
  variant?: 'active' | 'inactive' | 'pending'
}

function Badge({ title, className, variant = 'inactive' }: BadgeProps) {
  const variants = {
    active: "",
    inactive: "bg-gray-100 text-gray-800",
    pending: "",
  };

  const icons = {
    active: "bg-green-600",
    inactive: "bg-gray-800",
    pending: "bg-yellow-800",
  };

  const titles = {
    active: 'Active now',
    inactive: 'Offline',
    pending: 'Inactive',
  };

  return (
    <span className={`text-xs text-gray-800 font-medium inline-flex items-center px-2.5 py-0.5 rounded-md border border-gray-400 shadow-sm ${className} ${variants[variant]}`}>
      <span className={`w-1.5 h-1.5 rounded-full mr-1.5 ${icons[variant]}`}></span>
      {title || titles[variant]}
    </span>
  );
}

interface PillsBadgeProps {
  title?: string
  className?: string
  variant?: 'green' | 'red' | 'blue'
  children?: React.ReactNode
}

function PillsBadge({ title, className, variant = 'green', children }: PillsBadgeProps) {
  const variants = {
    green: "border border-green-400 text-green-600 bg-green-100",
    red: "border-red-600 text-red-900 bg-red-100",
    blue: "border-blue-600 text-blue-900 bg-blue-100",
  };

  return (
    <span className={`text-xs w-fit font-medium inline-flex items-center px-2 py-0.5 rounded-full ${className} ${variants[variant]}`}>
      {children} {title}
    </span>
  );
}

export { PillsBadge }

export default Badge;