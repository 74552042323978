import { baseApi } from "./baseQuery";

const settingsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({

    updateGeneralSettings: build.mutation({
      query: (data) => ({
        url: "api/v1/update-general-settings",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['generalSettings']
    }),

    getGeneralSettings: build.query({
      query: () => {
        return {
          url: `/api/v1/user-general-settings`,
        }
      },
      providesTags: ['generalSettings']
    }),

    getTeamMembers: build.query({
      query: () => {
        return {
          url: `/api/v1/user-team-members`,
        }
      },
      providesTags: ['teams']
    }),

    updateNotificationSettings: build.mutation({
      query: (data) => ({
        url: "api/v1/update-notification-settings",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['notificationSettings']
    }),

    getNotificationSettings: build.query({
      query: () => {
        return {
          url: `/api/v1/user-notification-settings`,
        }
      },
      providesTags: ['notificationSettings']
    }),

    inviteTeamMember: build.mutation({
      query: (data) => ({
        url: "api/v1/add-team-members",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['teams']
    }),

    removeTeamMember: build.mutation({
      query: (data) => ({
        url: "api/v1/remove-team-member",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['teams']
    }),

    updateTeamMember: build.mutation({
      query: (data) => ({
        url: "api/v1/update-team-member-status",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['teams']
    }),

    createCard: build.mutation({
      query: (data) => ({
        url: "api/v1/save-billing-card-details",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['billing']
    }),

    getCard: build.query({
      query: () => {
        return {
          url: `/api/v1/get-billing-card-details`,
        }
      },
      providesTags: ['billing']
    }),

    savePaymentEmail: build.mutation({
      query: (data) => ({
        url: "api/v1/send-billing-email",
        method: "POST",
        body: data
      }),
      invalidatesTags: ['billing']
    }),

    updateCard: build.mutation({
      query: (data) => ({
        url: "api/v1/update-billing-card-details/"+data.id,
        method: "PUT",
        body: data.data
      }),
      invalidatesTags: ['billing']
    }),

    makeDefaultCard: build.mutation({
      query: (data) => ({
        url: "api/v1/set-billing-card-default/"+data.id,
        method: "POST",
        body: data.data
      }),
      invalidatesTags: ['billing']
    }),



  }),

  // @ts-ignore
  overrideExisting: module.hot?.status() === "apply",
});

export const {
  useUpdateGeneralSettingsMutation,
  useGetGeneralSettingsQuery,
  useGetTeamMembersQuery,
  useUpdateNotificationSettingsMutation,
  useGetNotificationSettingsQuery,
  useInviteTeamMemberMutation,
  useRemoveTeamMemberMutation,
  useUpdateTeamMemberMutation,

  useCreateCardMutation,
  useGetCardQuery,
  useSavePaymentEmailMutation,
  useUpdateCardMutation,
  useMakeDefaultCardMutation
} = settingsApi;
