import { useRef, useState } from "react";
import { ArrowRightIcon, ConfirmConnectionIcon, GoogleAdsConnectIcon, MinusExpandIcon, PauseIcon, PlayButtonIcon, PlusExpandIcon, PlusIcon, SlandingGaIcon, SlandingShopifyIcon, StartHereIcon, TalkToDataIcon } from "../../components/Icons/iconImages";
import "./style.css"
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons";

const FirstTime = () => {

    const data = [
        {
            label: "Is my data safe with Oogwai?",
            text: "Absolutely. Data security is our top priority. Oogwai uses advanced encryption protocols and secure servers to ensure your data remains confidential and protected. We never share or sell your data, and you retain full ownership of your information at all times."
        },
        {
            label: "How much does Oogwai cost?",
            text: "Oogwai offers flexible pricing starting at just $20 per month per login. There are no hidden fees, and you can scale your subscription based on your team’s needs. Try it risk-free with our free trial to experience the value before committing."
        },
        {
            label: "I’m not tech-savvy. How easy is it to set up?",
            text: "Oogwai is designed with simplicity in mind. Connecting your data takes less than 2 minutes, and no technical expertise is required. Our intuitive interface guides you step-by-step, and our support team is always available to help if needed."
        },
        {
            label: "What if I need help or have questions?",
            text: "Our dedicated customer support team is available 24/7 to assist you. Whether you have questions about setup, features, or troubleshooting, we’re just a message or call away."
        },
        {
            label: "Can I control who has access to my data?",
            text: "Yes! Oogwai gives you full control over user access and permissions. You can decide who can view or manage your data, ensuring only authorized team members have access."
        },
        {
            label: "What if I want to cancel my subscription?",
            text: "We believe in flexibility. You can cancel your subscription anytime with no penalties. However, we’re confident you’ll love Oogwai’s ability to transform the way you interact with your data."
        }
    ]
    const videoRef = useRef<any>(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const navigate = useNavigate();

    const toggleVideo = () => {
        const video = videoRef.current;
        video.muted = true;
        if (video) {
            if (isVideoPlaying) {
                video.pause();
            } else {
                video.play();
            }

            setIsVideoPlaying(!isVideoPlaying);
        }
    };

    const items: CollapseProps['items'] = data?.map((res: { label: string, text: string }, index: number) => {
        return {
            key: index + 1,
            label: <p className="text-[#181D27] font-medium text-lg">{res?.label}</p>,
            children: <div className="text-[#535862] text-base font-normal">{res?.text}</div>,
        }
    })

    const onChange = (key: string | string[]) => {
        console.log(key);
    };
    return (
        <div>
            <div className="flex items-center justify-center">
                <StartHereIcon className="relative left-36 top-22" />
                <p className="font-semibold text-base xl-text-lg text-[#1F7A50] relative left-36 top-22">Start here</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 mt-10 max-w-[1280px] mx-auto px-4">
                <div>
                    <p className="text-4xl text-[#181D27] font-semibold">Data Just Got Conversational</p>
                    <p className="text-[#414651] text-sm font-normal mt-3">With Oogwai, your data finally speaks your language.</p>
                    <p className="text-[#414651] text-sm font-normal mt-4">In just 2 minutes, connect your data to Oogwai and start having real-time conversations that transform complexity into clarity.
                        Because when your data talks, you take charge.</p>

                    <div className="flex items-center gap-5 mt-6">
                        <Button
                            icon={<PlusIcon className="w-4 h-4" />}
                            type="primary"
                            onClick={() => navigate('/integrations')}>
                            Connect Now
                        </Button>
                        <Button variant="text" color="primary" >See How to Connect</Button>
                    </div>
                </div>
                <div className="mt-5 md:mt-0">
                    <div className="flex">
                        <SlandingGaIcon />
                        <video className="h-60 w-full rounded-xl videoTag" controls={false} autoPlay loop muted={true}>
                            <source src={'videos/startHereVideo.mp4'} type="video/mp4" />
                        </video>
                    </div>
                    <SlandingShopifyIcon className="float-right margin_top_10" />
                </div>
            </div>
            <div className="bg-[#FAFAFA] mt-4">
                <div className="grid grid-cols-1 md:grid-cols-2 max-w-[1280px] mx-auto px-4 py-24">
                    <div>
                        <p className="text-[#1F7A50] text-base font-semibold">Support</p>
                        <p className="text-[#181D27] text-3xl font-semibold mt-2">FAQs</p>
                        <p className="text-[#535862] text-lg font-normal mt-3">Everything you need to know about the product and subscriptions.</p>
                    </div>
                    <div>
                        <Collapse
                            className="faqCollape"
                            defaultActiveKey={items?.map((panel, index: number) => index + 1)}
                            onChange={onChange}
                            expandIconPosition={'end'}
                            items={items}
                            expandIcon={({ isActive }) => isActive ? <MinusExpandIcon /> : <PlusExpandIcon />}
                        />
                    </div>
                </div>
            </div>

            <div className="mt-28 mb-24 max-w-[1280px] mx-auto px-4 space-y-10">
                <div className="">
                    <div>
                        <p className="text-[#1F7A50] font-semibold text-base text-center">Integrations</p>
                        <p className="text-[#181D27] text-3xl font-semibold mt-2 text-center">Get Started</p>
                        <p className="text-[#535862] text-xl font-normal mt-2 text-center">Connect to your Analytics account to talk to your data</p>
                    </div>
                    <div className="relative">
                        {
                            isVideoPlaying ?
                                <PauseIcon className="absolute z-10 videoPlayIcon cursor-pointer pauseIcon" onClick={toggleVideo} />
                                :
                                <PlayButtonIcon className="absolute z-10 videoPlayIcon cursor-pointer" onClick={toggleVideo} />
                        }

                        <video className="h-72 w-6/12 integrationVideo rounded-xl border mx-auto mt-4" ref={videoRef} controls={true} autoPlay={false} loop muted={true}>
                            <source src={'videos/startHereVideo.mp4'} type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className="mt-10">
                    <div className="grid grid-cols-1 md:grid-cols-3 justify-center">
                        <div className="">
                            <GoogleAdsConnectIcon className="mx-auto" />
                            <p className="text-[#181D27] text-xl font-semibold text-center">1. Select a platform</p>
                            <p className="text-[#535862] font-normal text-base text-center mt-2">Select your data source to get your data flowing.</p>
                            <p className="flex items-center justify-center mt-4 text-[#279964] font-semibold text-base">Connect <ArrowRightIcon /> </p>
                        </div>
                        <div className="">
                            <ConfirmConnectionIcon className="mx-auto" />
                            <p className="text-[#181D27] text-xl font-semibold text-center">2. Confirm Connection</p>
                            <p className="text-[#535862] font-normal text-base text-center mt-2">Change data platform end to data source.</p>
                        </div>
                        <div className="">
                            <TalkToDataIcon className="mx-auto" />
                            <p className="text-[#181D27] text-xl font-semibold text-center">3. Talk to your data</p>
                            <p className="text-[#535862] font-normal text-base text-center mt-2">Question your data and see it respond with answers.</p>
                            <p className="flex items-center justify-center mt-4 text-[#279964] font-semibold text-base">Try it <ArrowRightIcon /> </p>

                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                <Button
                    icon={<PlusIcon className="w-4 h-4" />}
                    type="primary"
                    onClick={() => navigate('/integrations')}>
                    Get Started
                </Button>
                </div>
                
            </div>
        </div>
    )
};

export default FirstTime;
