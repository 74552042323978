import React from 'react';

interface CardsProps {
  title?: string;
  classname?: string;
  childClassName?: string;
  bottomButton?: React.ReactNode;
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "grayCard" | "danger";
  onClick?: () => void;
  loading?: boolean;
}

function Cards({ title, classname = '', childClassName = '', bottomButton = '', children, variant = "primary", onClick, loading = true }: CardsProps) {
  const variants = {
    primary: `border border-solid shadow-sm bg-[#F5FAF7] border-green-200 ${bottomButton ? '' : 'p-4'}`,
    secondary: `gap-0.5rem rounded-[1rem] ${bottomButton ? '' : 'p-2'} border border-solid border-[#e9e9eb] bg-white shadow-[0_0.0625rem_0.125rem_0_#0a0c120c]`,
    grayCard: "bg-gray-100",
    danger: "bg-red-500 hover:bg-red-600 text-white",
  }

  return (
    <div className={`flex-col flex gap-2 rounded-2xl ${variants[variant]} ${classname} ${onClick ? 'cursor-pointer' : ''}`} onClick={onClick}>
      {title &&
        <h1 className="text-xl font-extrabold tracking-tight leading-8 text-neutral-700">
          {title}
        </h1>
      }
      <div className={`flex gap-1 ${childClassName}`}>
        {children}
      </div>
      {bottomButton && <div className="flex border-t p-5 border-solid justify-end border-gray-300" >{bottomButton}</div>}
    </div>
  )
}

export default Cards