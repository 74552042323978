import { createSlice } from "@reduxjs/toolkit";
import { BrandGAds, BrandGAnalytics, BrandMeta, BrandShopify } from "../../components/Icons/iconImages";

// Define the initial state for integrations
const initialState = {
  integrations: [
    {
      name: "googleanalytics",
      displayName: "Google Analytics",
      isPurchased: false,
      isUpgraded: true,
      icon: BrandGAnalytics,
      aboutOauth: [
        "Full support of GA4 including all goals, events, and custom metrics",
        "All your Plausible data in Slack including custom metrics",
        "Facebook Ads and Google Ads integration, get updates on your campaigns straight to Slack with daily and weekly ad reports. Daily monitoring of key metrics such as ad spend will keep you on target",
        "Track position changes on your SEO with Google Search console",
        "Shopify integration to keep you updated with your store’s performance. Get Shopify reports on your specific store metrics.",
        "Daily or hourly monitoring to give you peace of mind 24/7. Enhanced by AI to learn the patterns of data driving your business",
        "Advanced report builder to give you the power to easily customize your reports to suit the exact needs of your business Support for agencies. Get agency reports for your team and clients, and manage your accounts easily in one place"
      ],
      syncHistory: {
        lastSynced: new Date(),
        syncCount: 0,
        syncErrors: 0,
        data: [
          {
            date: new Date(Date.now() - 500000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 2000000),
            status: "failed",
          },
          {
            date: new Date(Date.now() - 15000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 25000000),
            status: "failed",
          },
        ],
      },
      // Add more properties as needed
    },
    {
      name: "shopify",
      displayName: "Shopify",
      isPurchased: false,
      isUpgraded: true,
      icon: BrandShopify,
      syncHistory: {
        lastSynced: new Date(Date.now() - Math.floor(Math.random() * 10000000)),
        syncCount: Math.floor(Math.random() * 10),
        syncErrors: Math.floor(Math.random() * 5),
        data: [
          {
            date: new Date(Date.now() - 10000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 5000000),
            status: "failed",
          },
          {
            date: new Date(Date.now() - 2000000),
            status: "success",
          },
        ],
      },
    },
    {
      name: "meta",
      displayName: "Meta",
      isPurchased: false,
      isUpgraded: true,
      icon: BrandMeta,
      syncHistory: {
        lastSynced: new Date(Date.now() - Math.floor(Math.random() * 5000000)),
        syncCount: Math.floor(Math.random() * 15),
        syncErrors: Math.floor(Math.random() * 3),
        data: [
          {
            date: new Date(Date.now() - 8000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 3000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 1000000),
            status: "success",
          },
        ],
      },

      // Add more properties as needed
    },
    {
      name: "googleads",
      displayName: "Google Ads",
      isPurchased: false,
      isUpgraded: true,
      icon: BrandGAds,
      syncHistory: {
        lastSynced: new Date(Date.now() - Math.floor(Math.random() * 20000000)),
        syncCount: 7,
        syncErrors: 2,
        data: [
          {
            date: new Date(Date.now() - 30000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 15000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 7000000),
            status: "success",
          },
          {
            date: new Date(Date.now() - 3500000),
            status: "success",
          },
        ],
      },
      // Add more properties as needed
    },
  ],
};

// Define a function to update the purchased status of an integration
const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    purchaseIntegration(state, action) {
      const integrationIndex = state.integrations.findIndex(
        (integration) => integration.name === action.payload.name
      );
      if (integrationIndex !== -1) {
        state.integrations[integrationIndex].isPurchased = !state.integrations[integrationIndex].isPurchased;
        // Update other properties as needed
      }
    },

  },
});

export const { purchaseIntegration } = integrationsSlice.actions;
export default integrationsSlice.reducer;

