import { Controller } from 'react-hook-form';

import './styles.css'; // Import CSS for styling and animation

interface ToggleButtonProps {
  control: any;
  label?: string;
  rules?: any;
  name: string;
}

const ToggleButton = ({ control, label, rules, name } : ToggleButtonProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <label className="toggle-button inline-flex items-center cursor-pointer">
          <input type="checkbox" value="" className="sr-only peer" onChange={onChange} checked={value} />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-500"></div>
          {label && <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>}
        </label>
      )}
    />
  );
};


export default ToggleButton;