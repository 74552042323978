// ! data for the chart
const barChartData = [
  { month: 'Jan', Trend: 15, },
  { month: 'Feb', Trend: 20, },
  { month: 'Mar', Trend: 3, },
  { month: 'Apr', Trend: 5, },
  { month: 'May', Trend: 9, },
  { month: 'Jun', Trend: 11, },
  { month: 'Jul', Trend: 18, },
  { month: 'Aug', Trend: 21, },
  { month: 'Sep', Trend: 3.5, },
  { month: 'Oct', Trend: 6, },
  { month: 'Nov', Trend: 10, },
  { month: 'Dec', Trend: 13, },
];
// !
const optionsData = [
  {
    value: "option1",
    label: "Option 1",
  },
  {
    value: "option2",
    label: "Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
  },
];

const dateRange = [
  {
    value: "allTime",
    label: "All Time",
  },  
  {
    value: "last1Year",
    label: "Last 1 Year"
  },
  {
    value: "last6Month",
    label: "Last 6 months"
  },
  {
    value: "last3Month",
    label: "Last 3 months"
  },
  {
    value: "last1Month",
    label: "Last 1 month",
  },
  {
    value: "last7Days",
    label: "Last 7 Days",
  },
]

// ! Dashboard sidebar tabs
const dataSourcesTabs = [
  {
    key: 1,
    label: 'My Details',
    value: 'myDetails',
  },
  {
    key: 2,
    label: 'Analytics',
    value: 'analytics',
  },
  {
    key: 3,
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    key: 4,
    label: 'Storefront',
    value: 'storefront',
  }
]

const durationDataTabs = [
  {
    id: 0,
    label: 'All time',
    value: 'allTime',
  },
  {
    id: 5,
    label: 'Yesterday',
    value: 'yesterday',
  },
  {
    id: 6,
    label: 'Last 7 days',
    value: 'last7Days',
  },
  {
    id: 7,
    label: 'Last 14 days',
    value: 'last14Days',
  },
  {
    id: 8,
    label: 'Last 30 days',
    value: 'last30Days',
  },
  {
    id: 9,
    label: 'Last 90 days',
    value: 'last90Days',
  },
  {
    id: 10,
    label: 'This month',
    value: 'thisMonth',
  },
  {
    id: 11,
    label: 'Last month',
    value: 'lastMonth',
  },
  {
    id: 12,
    label: 'This year',
    value: 'thisYear',
  },
  {
    id: 13,
    label: 'Last year',
    value: 'lastYear',
  },
]


// ! Dashboard prompt suggestions
const promptData = [
  {
    title: 'What is the conversion rate of online store sessions from organic search?',
    desc:
      `<p>In Simpler terms:</p>
      <ul>
        <li>"<b>Online store sessions</b>" refers to visits to an online store.</li>
        <li>"Organic search" means visitors who found the store through a regular Google search, not through paid ads.</li>
        <li>"<b>Conversion rate</b>" is the percentage of these visitors who actually end up buying something or doing what the store wants (like signing up or downloading something).</li>
      </ul>
      <p>So, the question is essentially asking: Of the people who find this <b>online store</b> by searching for it on Google (without clicking on ads), how many end up making a purchase or completing a specific action?</p>
      <p>It’s a way to measure how effective organic search is at bringing in customers who end up buying.</p>
      `,
  },
  {
    title: 'Which countries generate the highest total sales?',
    desc:
      `<p><b>Top 5 countries</b> are the countries with the highest total sales. The top 5 countries are the countries with the highest total sales.</p>
      <ul>
        <li>The top 5 countries are the countries that have generated the highest total sales.</li>
        <li>This metric can help you understand which countries are most valuable to your business.</li>
        <li>You can use this information to target marketing campaigns and optimize your website for users in these countries.</li>
      </ul>`,
  },
  {
    title: 'What is the bounce rate for users who have initiated checkout?',
    desc:
      `<p><b>Bounce rate</b> is the percentage of users who visit your website and leave without completing a transaction.</p>
      <ul>
        <li>Bounce rate is the percentage of users who visit your website and leave without completing a transaction.</li>
        <li>This metric can help you understand how effective your website is at converting visitors into customers.</li>
        <li>You can use this information to optimize your website and increase sales.</li>
      </ul>
      <p><b>What is the bounce rate for users who have initiated checkout?</b></p>
      <ul>
        <li>The bounce rate for users who have initiated checkout is the percentage of users who visit your website and leave without completing a transaction.</li>
        <li>This metric can help you understand how effective your website is at converting visitors into customers.</li>
        <li>You can use this information to optimize your website and increase sales.</li>
      </ul>
      `,
  },
  {
    title: 'What is the gross profit margin by product category?',
    desc:
      `<p><b>Gross profit margin</b> is the percentage of the total sales price that is profit.</p>
      <ul>
        <li>Gross profit margin is the percentage of the total sales price that is profit.</li>
        <li>This metric can help you understand how effective your website is at converting visitors into customers.</li>
        <li>You can use this information to optimize your website and increase sales.</li>
      </ul>
      <p><b>What is the gross profit margin by product category?</b></p>
      <ul>
        <li>The gross profit margin by product category is the percentage of the total sales price that is profit.</li>
        <li>This metric can help you understand how effective your website is at converting visitors into customers.</li>
        <li>You can use this information to optimize your website and increase sales.</li>
      </ul>
      `,
  }
]

// ! Settings > Teams > table data
const teamDummyData = [
  {
    id: '1a2b3c4d-5e6f-7g8h-9i0j-1a2b3c4d5e6f',
    name: 'Alice Johnson',
    avatar: 'https://randomuser.me/api/portraits/women/12.jpg',
    role: 'CTO',
    email: 'alice.johnson@example.com',
    teamId: 'team-a',
    inviteStatus: 'accepted',
    isAdmin: true,
  },
  {
    id: '7h8i9j0k-1a2b-3c4d-5e6f-7g8h9i0j1a2b',
    name: 'Michael Smith',
    avatar: 'https://randomuser.me/api/portraits/men/22.jpg',
    role: 'CFO',
    email: 'michael.smith@example.com',
    teamId: 'team-a',
    inviteStatus: 'accepted',
    isAdmin: false,
  },
  {
    id: '2b3c4d5e-6f7g-8h9i-0j1a-2b3c4d5e6f7g',
    name: 'Sophia Brown',
    avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
    role: 'COO',
    email: 'sophia.brown@example.com',
    teamId: 'team-b',
    inviteStatus: 'Invite Pending',
    isAdmin: true,
  },
  {
    id: '8h9i0j1a-2b3c-4d5e-6f7g-8h9i0j1a2b3c',
    name: 'James Williams',
    avatar: 'https://randomuser.me/api/portraits/men/42.jpg',
    role: 'CMO',
    email: 'james.williams@example.com',
    teamId: 'team-b',
    inviteStatus: 'accepted',
    isAdmin: false,
  },
  {
    id: '3c4d5e6f-7g8h-9i0j-1a2b-3c4d5e6f7g8h',
    name: 'Emma Davis',
    avatar: 'https://randomuser.me/api/portraits/women/52.jpg',
    role: 'CIO',
    email: 'emma.davis@example.com',
    teamId: 'team-c',
    inviteStatus: 'Invite Pending',
    isAdmin: true,
  }
]

// ! Settings > Explore Plan Addons
const explorePlanAddonsData = {
  title: 'Explore Plan Addons',
  description: 'Running out of user seats?',
  plan: {
    title: 'Basic',
    price: {
      monthly: 10,
      yearly: 100,
    },
    currency: '$',
    description: 'Our most popular plan.',
  },
  items: [
    {
      label: '10 users',
      description: 'Add up to 10 team members.',
      active: true,
    },
    {
      label: '20 GB data',
      description: 'Up to 20 GB individual data.',
      active: true,
    },
    {
      label: 'All features',
      description: 'Access to advanced features and analytics.',
      active: true,
    },
  ]
}

const billingData = {
  radioOptions: [
    { value: 'isAccountEmail', label: 'Send to my account email' },
    { value: 'isAlternativeEmail', label: 'Send to an alternative email' },
  ],
}

// ! Settings > Billing > Card Details
const cardTypes = [
  { name: "Visa", pattern: /^4[0-9]{0,15}/ },
  { name: "Mastercard", pattern: /^5[1-5][0-9]{0,14}/ },
  { name: "American Express", pattern: /^3[47][0-9]{0,13}/ },
  { name: "Discover", pattern: /^6(?:011|5[0-9]{2})[0-9]{0,12}/ },
  { name: "Diners Club", pattern: /^3(?:0[0-5]|[68][0-9])[0-9]{0,11}/ },
  { name: "JCB", pattern: /^(?:2131|1800|35\d{3})\d{0,11}/ },
];

export {
  optionsData,
  barChartData, // ! dumym data
  promptData, //
  dateRange,
  teamDummyData, // ! dumym data
  explorePlanAddonsData, // !dummy data later it will come from backend
  billingData,
  cardTypes,
};

export { dataSourcesTabs, durationDataTabs }


export const TimeZones = [
  'Europe/Andorra',
  'Asia/Dubai',
  'Asia/Kabul',
  'Europe/Tirane',
  'Asia/Yerevan',
  'Antarctica/Casey',
  'Antarctica/Davis',
  'Antarctica/DumontDUrville', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Antarctica/Mawson',
  'Antarctica/Palmer',
  'Antarctica/Rothera',
  'Antarctica/Syowa',
  'Antarctica/Troll',
  'Antarctica/Vostok',
  'America/Argentina/Buenos_Aires',
  'America/Argentina/Cordoba',
  'America/Argentina/Salta',
  'America/Argentina/Jujuy',
  'America/Argentina/Tucuman',
  'America/Argentina/Catamarca',
  'America/Argentina/La_Rioja',
  'America/Argentina/San_Juan',
  'America/Argentina/Mendoza',
  'America/Argentina/San_Luis',
  'America/Argentina/Rio_Gallegos',
  'America/Argentina/Ushuaia',
  'Pacific/Pago_Pago',
  'Europe/Vienna',
  'Australia/Lord_Howe',
  'Antarctica/Macquarie',
  'Australia/Hobart',
  'Australia/Currie',
  'Australia/Melbourne',
  'Australia/Sydney',
  'Australia/Broken_Hill',
  'Australia/Brisbane',
  'Australia/Lindeman',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Perth',
  'Australia/Eucla',
  'Asia/Baku',
  'America/Barbados',
  'Asia/Dhaka',
  'Europe/Brussels',
  'Europe/Sofia',
  'Atlantic/Bermuda',
  'Asia/Brunei',
  'America/La_Paz',
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco',
  'America/Nassau',
  'Asia/Thimphu',
  'Europe/Minsk',
  'America/Belize',
  'America/St_Johns',
  'America/Halifax',
  'America/Glace_Bay',
  'America/Moncton',
  'America/Goose_Bay',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Nipigon',
  'America/Thunder_Bay',
  'America/Iqaluit',
  'America/Pangnirtung',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Rainy_River',
  'America/Resolute',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Swift_Current',
  'America/Edmonton',
  'America/Cambridge_Bay',
  'America/Yellowknife',
  'America/Inuvik',
  'America/Creston',
  'America/Dawson_Creek',
  'America/Fort_Nelson',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Dawson',
  'Indian/Cocos',
  'Europe/Zurich',
  'Africa/Abidjan',
  'Pacific/Rarotonga',
  'America/Santiago',
  'America/Punta_Arenas',
  'Pacific/Easter',
  'Asia/Shanghai',
  'Asia/Urumqi',
  'America/Bogota',
  'America/Costa_Rica',
  'America/Havana',
  'Atlantic/Cape_Verde',
  'America/Curacao',
  'Indian/Christmas',
  'Asia/Nicosia',
  'Asia/Famagusta',
  'Europe/Prague',
  'Europe/Berlin',
  'Europe/Copenhagen',
  'America/Santo_Domingo',
  'Africa/Algiers',
  'America/Guayaquil',
  'Pacific/Galapagos',
  'Europe/Tallinn',
  'Africa/Cairo',
  'Africa/El_Aaiun',
  'Europe/Madrid',
  'Africa/Ceuta',
  'Atlantic/Canary',
  'Europe/Helsinki',
  'Pacific/Fiji',
  'Atlantic/Stanley',
  'Pacific/Chuuk',
  'Pacific/Pohnpei',
  'Pacific/Kosrae',
  'Atlantic/Faroe',
  'Europe/Paris',
  'Europe/London',
  'Asia/Tbilisi',
  'America/Cayenne',
  'Africa/Accra',
  'Europe/Gibraltar',
  'America/Godthab',
  'America/Danmarkshavn',
  'America/Scoresbysund',
  'America/Thule',
  'Europe/Athens',
  'Atlantic/South_Georgia',
  'America/Guatemala',
  'Pacific/Guam',
  'Africa/Bissau',
  'America/Guyana',
  'Asia/Hong_Kong',
  'America/Tegucigalpa',
  'America/Port-au-Prince',
  'Europe/Budapest',
  'Asia/Jakarta',
  'Asia/Pontianak',
  'Asia/Makassar',
  'Asia/Jayapura',
  'Europe/Dublin',
  'Asia/Jerusalem',
  'Asia/Kolkata',
  'Indian/Chagos',
  'Asia/Baghdad',
  'Asia/Tehran',
  'Atlantic/Reykjavik',
  'Europe/Rome',
  'America/Jamaica',
  'Asia/Amman',
  'Asia/Tokyo',
  'Africa/Nairobi',
  'Asia/Bishkek',
  'Pacific/Tarawa',
  'Pacific/Enderbury',
  'Pacific/Kiritimati',
  'Asia/Pyongyang',
  'Asia/Seoul',
  'Asia/Almaty',
  'Asia/Qyzylorda',
  'Asia/Qostanay', // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  'Asia/Aqtobe',
  'Asia/Aqtau',
  'Asia/Atyrau',
  'Asia/Oral',
  'Asia/Beirut',
  'Asia/Colombo',
  'Africa/Monrovia',
  'Europe/Vilnius',
  'Europe/Luxembourg',
  'Europe/Riga',
  'Africa/Tripoli',
  'Africa/Casablanca',
  'Europe/Monaco',
  'Europe/Chisinau',
  'Pacific/Majuro',
  'Pacific/Kwajalein',
  'Asia/Yangon',
  'Asia/Ulaanbaatar',
  'Asia/Hovd',
  'Asia/Choibalsan',
  'Asia/Macau',
  'America/Martinique',
  'Europe/Malta',
  'Indian/Mauritius',
  'Indian/Maldives',
  'America/Mexico_City',
  'America/Cancun',
  'America/Merida',
  'America/Monterrey',
  'America/Matamoros',
  'America/Mazatlan',
  'America/Chihuahua',
  'America/Ojinaga',
  'America/Hermosillo',
  'America/Tijuana',
  'America/Bahia_Banderas',
  'Asia/Kuala_Lumpur',
  'Asia/Kuching',
  'Africa/Maputo',
  'Africa/Windhoek',
  'Pacific/Noumea',
  'Pacific/Norfolk',
  'Africa/Lagos',
  'America/Managua',
  'Europe/Amsterdam',
  'Europe/Oslo',
  'Asia/Kathmandu',
  'Pacific/Nauru',
  'Pacific/Niue',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'America/Panama',
  'America/Lima',
  'Pacific/Tahiti',
  'Pacific/Marquesas',
  'Pacific/Gambier',
  'Pacific/Port_Moresby',
  'Pacific/Bougainville',
  'Asia/Manila',
  'Asia/Karachi',
  'Europe/Warsaw',
  'America/Miquelon',
  'Pacific/Pitcairn',
  'America/Puerto_Rico',
  'Asia/Gaza',
  'Asia/Hebron',
  'Europe/Lisbon',
  'Atlantic/Madeira',
  'Atlantic/Azores',
  'Pacific/Palau',
  'America/Asuncion',
  'Asia/Qatar',
  'Indian/Reunion',
  'Europe/Bucharest',
  'Europe/Belgrade',
  'Europe/Kaliningrad',
  'Europe/Moscow',
  'Europe/Simferopol',
  'Europe/Kirov',
  'Europe/Astrakhan',
  'Europe/Volgograd',
  'Europe/Saratov',
  'Europe/Ulyanovsk',
  'Europe/Samara',
  'Asia/Yekaterinburg',
  'Asia/Omsk',
  'Asia/Novosibirsk',
  'Asia/Barnaul',
  'Asia/Tomsk',
  'Asia/Novokuznetsk',
  'Asia/Krasnoyarsk',
  'Asia/Irkutsk',
  'Asia/Chita',
  'Asia/Yakutsk',
  'Asia/Khandyga',
  'Asia/Vladivostok',
  'Asia/Ust-Nera',
  'Asia/Magadan',
  'Asia/Sakhalin',
  'Asia/Srednekolymsk',
  'Asia/Kamchatka',
  'Asia/Anadyr',
  'Asia/Riyadh',
  'Pacific/Guadalcanal',
  'Indian/Mahe',
  'Africa/Khartoum',
  'Europe/Stockholm',
  'Asia/Singapore',
  'America/Paramaribo',
  'Africa/Juba',
  'Africa/Sao_Tome',
  'America/El_Salvador',
  'Asia/Damascus',
  'America/Grand_Turk',
  'Africa/Ndjamena',
  'Indian/Kerguelen',
  'Asia/Bangkok',
  'Asia/Dushanbe',
  'Pacific/Fakaofo',
  'Asia/Dili',
  'Asia/Ashgabat',
  'Africa/Tunis',
  'Pacific/Tongatapu',
  'Europe/Istanbul',
  'America/Port_of_Spain',
  'Pacific/Funafuti',
  'Asia/Taipei',
  'Europe/Kiev',
  'Europe/Uzhgorod',
  'Europe/Zaporozhye',
  'Pacific/Wake',
  'America/New_York',
  'America/Detroit',
  'America/Kentucky/Louisville',
  'America/Kentucky/Monticello',
  'America/Indiana/Indianapolis',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Vevay',
  'America/Chicago',
  'America/Indiana/Tell_City',
  'America/Indiana/Knox',
  'America/Menominee',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/North_Dakota/Beulah',
  'America/Denver',
  'America/Boise',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Juneau',
  'America/Sitka',
  'America/Metlakatla',
  'America/Yakutat',
  'America/Nome',
  'America/Adak',
  'Pacific/Honolulu',
  'America/Montevideo',
  'Asia/Samarkand',
  'Asia/Tashkent',
  'America/Caracas',
  'Asia/Ho_Chi_Minh',
  'Pacific/Efate',
  'Pacific/Wallis',
  'Pacific/Apia',
  'Africa/Johannesburg'
];
