import React, { useEffect, useState } from "react";
// COMPONENTS
import Cards from "../../components/Cards";

// CSS
import "./Dashboard.css";
import Icons from "../../components/Icons";
import { ChevronDownIcon, CloseIcon, MagicWandIcon, OogwaiLogo2, OogwaiTextLogo, PlusIcon, TrashIcon } from "../../components/Icons/iconImages";
import { useSelector } from "react-redux";
import useModal from "../../Hooks/useModal";
import { ModalBottomOverlay, Modal } from "../../components/Modal";
import {
  useGetAnalyticStatusQuery,
  useLazyIntegrationQuery,
  useLazyRemoveGAQuery,
  useLazyVerifyGoogleTokenQuery,
  useLazyShopifyIntegrationQuery,
  useLazyVerifyShopifyQuery,
  useLazyMetaIntegrationQuery,
  useLazyRemoveShopifyQuery,
  useLazyRemoveMetaQuery,
  useLazyListAnalyticsQuery,
} from "../../services/integrationSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { toast } from "react-toastify";
import { auth } from "../../firebase/firebase";
import Badge from "../../components/Badge";
import { Button } from "../../components/Buttons";
import Checkbox from "../../components/Checkbox";
import { useForm } from "react-hook-form";
import IconRings from "../../components/IconRings";
import InputField from "../../components/InputField";
// TODO: Need to work on the modal stylings

const FirstIntegrations = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("oogw.ai_user");
  const user = auth.currentUser;
  const userEmail = user?.email || 'No email id found';

  const { integrations } = useSelector((state) => state.integrations);

  // ? MODAL FOR CHECKING SYNC AND DETAILS OF INTEGRATION
  const {
    isOpen: isDetailsOverlayOpen,
    openModal: openDetailsOverlayModal,
    closeModal: closeDetailsOverlayModal,
    modalData: detailsOverlayModalData
  } = useModal();

  // ? MODAL AFTER REDIRECTION
  const {
    isOpen: isOauthOpen,
    openModal: oauthOpenModal,
    closeModal: oauthCloseModal,
    modalData: oauthModalData
  } = useModal();

  // ? MODAL FOR DELETING INTEGRATION
  const {
    isOpen: isDeleteOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
    modalData: deleteModalData
  } = useModal();

  // ? MODAL FOR SHOPIFY INTEGRATION
  const {
    isOpen: isShopifyOpen,
    openModal: openShopifyModal,
    closeModal: closeShopifyModal,
    modalData: shopifyModalData
  } = useModal();

  const { control, getValues } = useForm({
    defaultValues: {
      dontShowAgain: false,
    },
    shopUrl: '',
  });

  const [lazyIntegration] = useLazyIntegrationQuery();
  const [gooleVerify] = useLazyVerifyGoogleTokenQuery();
  const [listAnalytics] = useLazyListAnalyticsQuery();
  const [removeGA] = useLazyRemoveGAQuery();
  const { data: getStatus, refetch, error } = useGetAnalyticStatusQuery();


  // shopify 
  const [integrateShopify] = useLazyShopifyIntegrationQuery();
  const [shopifyVerify] = useLazyVerifyShopifyQuery();
  const [removeShopify] = useLazyRemoveShopifyQuery();

  // meta
  const [metaIntegration] = useLazyMetaIntegrationQuery();
  const [removeMeta] = useLazyRemoveMetaQuery();

  // bottom modal expansion
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIntegrationData = () => {
    const integrationData = detailsOverlayModalData?.data;
    if (detailsOverlayModalData) {
      return {
        icon: integrationData?.icon && (
          <div className="flex items-center">
            {React.createElement(integrationData.icon, { className: "w-16 h-auto" })}
          </div>
        ),
        overLayIcon: integrationData?.icon && (
          <div className="inline-flex rounded-md items-center size-fit border border-gray-300 bg-white">
            {React.createElement(integrationData.icon, { className: "w-28 h-28" })}
          </div>
        ),
        syncHistory: integrationData?.syncHistory,
        lastSynced: integrationData?.lastSynced ? new Date(integrationData.lastSynced).toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }) : '22 Jan 2024 at 12:33pm',
        displayName: integrationData?.displayName,
        aboutOauth: integrationData?.aboutOauth,
        integrationName: integrationData?.name,
      };
    }
  }

  // if (error) {
  //   toast.error(error?.data?.message || 'Unexpected error');
  // }

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const shopifyCode = {
    code: urlParams.get("code"),
    hmac: urlParams.get("hmac"),
    shop: urlParams.get("shop"),
    state: urlParams.get("state"),
    basePath: urlParams.get("basePath")
  };

  useEffect(() => {
    if (code) {
      oauthOpenModal('accessGranted');
    }
  }, [code]);

  const handleAdd = async (service) => {
    const { name } = service;
    console.log(`Add ${name} integration button is clicked.`);
    if (name === 'googleanalytics') {
      try {
        const payload = {
          token,
          basepath: 'onboardingintegration'
        }
        const response = await lazyIntegration(payload);
        console.log("response", response, response?.error?.data?.message);
        // Redirect manually based on the `Location`
        if (response && response?.data) {
          window.location.href = response?.data?.authorization_url;
          refetch();
        } else if (response?.error) {
          toast.error(response?.error?.data?.message || 'Unexpected error');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
    if (name === 'shopify') {
      openShopifyModal('shopify');
    }
    if (name === 'meta') {
      try {
        const response = await metaIntegration(token);
        // console.log("response", response, response?.error?.data?.message);
        // Redirect manually based on the `Location`
        if (response && response?.data) {
          window.location.href = response?.data;
          refetch();
        } else if (response?.error) {
          toast.error(response?.error?.data?.message || 'Unexpected error');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const handleRemove = async () => {
    const serviceName = handleIntegrationData()?.integrationName;
    if (serviceName === 'googleanalytics') {
      removeGA()
        .unwrap()
        .then((response) => {
          refetch();
          toast.success(response?.message || 'Account removed successfully');
          closeDeleteModal();
          closeDetailsOverlayModal();
        })
        .catch((error) => {
          toast.error(error?.data?.error || 'Unexpected error');
          closeDeleteModal();
        });
    }
    if (serviceName === 'shopify') {
      removeShopify()
        .unwrap()
        .then((response) => {
          refetch();
          toast.success(response?.message || 'Account removed successfully');
          closeDeleteModal();
          closeDetailsOverlayModal();
        })
        .catch((error) => {
          toast.error(error?.data?.error || 'Unexpected error');
          closeDeleteModal();
        });
    }
    if (serviceName === 'meta') {
      removeMeta()
        .unwrap()
        .then((response) => {
          refetch();
          toast.success(response?.message || 'Account removed successfully');
          closeDeleteModal();
          closeDetailsOverlayModal();
        })
        .catch((error) => {
          toast.error(error?.data?.error || 'Unexpected error');
          closeDeleteModal();
        })
    }
  };

  const handleShopify = async () => {
    try {
      let payload = {
        token: token,
        data: getValues('shopUrl'),
        basepath: 'onboardingintegration',
      }
      const response = await integrateShopify(payload);
      console.log("response", response, response?.error?.data?.message);
      if (response && response?.data) {
        window.location.href = response?.data;
        refetch();
      } else if (response?.error) {
        throw new Error(response?.error?.data?.message || 'Unexpected error');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error.message);
    }
  }

  const handleSubmitApprove = () => {
    setLoading(true)
    if (location.pathname.includes('oauth/google')) {
      gooleVerify(code)
        .unwrap()
        .then((response) => {
          setLoading(false)
          oauthCloseModal();
          if (response) {
            refetch();
            listAnalytics()
              .unwrap()
              .then(() => { })
              .catch((error) => console.error('Error:', error));
            toast.success('Account added successfully');
          }
        })
        .catch((error) => {
          toast.error(error?.data?.error || 'Unexpected error');
        })
        .finally(() => {
          setLoading(false)
          oauthCloseModal();
          navigate("/onboardingIntegration")
        })
    }

    if (location.pathname.includes('shopify/auth')) {
      shopifyVerify(shopifyCode)
        .unwrap()
        .then((response) => {
          setLoading(false)
          oauthCloseModal();
          if (response) {
            refetch();
            toast.success('Account added successfully');
          }
          navigate("/onboardingIntegration")

        })
        .catch((error) => {
          toast.error(error?.data?.error || 'Unexpected error');
        })
        .finally(() => {
          setLoading(false)
          oauthCloseModal();
          navigate("/onboardingIntegration")
        })
    }
  }

  const handleDetailsModal = (service) => {
    const { name } = service;
    openDetailsOverlayModal(name, integrations.find((integration) => integration.name === name));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <section className="relative" style={{ height: `calc(max(100vh - 200px, 540px))` }}>
        <div className="h-5 overflow-visible">
          <IconRings ringColor={'#b6f0d5ab'} numRings={10} ringBaseSize={26} ringSpacing={80} className="mt-32" />
        </div>
        <div className="relative flex flex-col h-full justify-between rounded-3xl bg-white max-w-[576px] mx-auto border border-gray-200 shadow-sm" >
          <div className="p-8">
            <div className="flex flex-col pb-4 items-center">
              <OogwaiLogo2 className="w-[72px] h-[72px]" />
              <OogwaiTextLogo />
            </div>
            <div className="flex flex-col gap-2 items-center">

              <h3 className="text-[#414651]">Welcome to Oogw.ai</h3>
              <p className="text-sm">Connect to an Analytics platform you use</p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 pt-14 gap-4">
              {integrations.map((integrate) => {
                if (integrate.name === 'meta' || integrate.name === 'googleads') return null;
                return (
                  <Cards
                    key={integrate.name}
                    className={`${!integrate.isUpgraded && "border-0 bg-black-100"} ${getStatus && getStatus[integrate?.name] ? "cursor-pointer" : "cursor-default"}`}
                    variant="secondary"
                    childClassName="flex-col items-start p-4 gap-4"
                    onClick={(e) => {
                      if (getStatus && getStatus[integrate?.name]) {
                        e.stopPropagation(); // Prevent triggering Card's onClick
                        handleDetailsModal(integrate);
                      }
                    }}
                  >
                    <div className={'flex justify-start items-center gap-1'}>
                      {React.createElement(integrate.icon, { className: "w-12 h-auto" })}
                      <h5 className="font-medium text-text-700 font-albert">{integrate.displayName}</h5>
                    </div>
                    <Button
                      type={getStatus && getStatus[integrate?.name] ? "primary" : "default"}
                      icon={
                        (getStatus && getStatus[integrate?.name]) ? <Icons
                          icon={"heroicons-outline:check"}
                          alt="search-icon"
                          className={"h-[1.25rem] w-[1.25rem] text-white shadow-sm"}
                        /> : <PlusIcon />
                      }
                      disabled={error?.data?.message}
                      onClick={(e) => {
                        if (getStatus && !getStatus[integrate?.name]) {
                          e.stopPropagation();
                          handleAdd(integrate)
                        }
                      }
                      }
                    >
                      {getStatus && getStatus[integrate?.name] ? "Added" : "Connect"}
                    </Button>
                  </Cards>
                )
              })}
            </div>
          </div>

          <div className="px-6 py-3 border-t border-gray-200 flex justify-between items-center">
            <Button
              type="text"
              iconPosition="left"
              onClick={() => navigate('/dashboard')}
            >
              Skip
            </Button>
            <Button
              type="primary"
              disabled={
                Object.entries(getStatus || {})
                  .filter(([key, value]) => typeof value === 'boolean')
                  .every(([key, value]) => !value)
              }
              onClick={() => {
                // navigate('/dashboard');
                 window.location.href='/dashboard'
              }}
            >
              Take me to Dashboard
            </Button>
          </div>
        </div>
        {/* <IconRings ringColor={'#b6f0d5ab'} numRings={9} ringBaseSize={10} ringSpacing={115} className="-z-0" /> */}

      </section >

      {isShopifyOpen && (
        <Modal
          title="Connect Shopify"
          open={isShopifyOpen}
          onClose={closeShopifyModal}
        >
          <div className="flex flex-col gap-4 mt-8" onClick={(e) => e.stopPropagation()}>
            <InputField
              label="Shop URL"
              name="shopUrl"
              control={control}
              placeholder="example.myshopify.com"
              // value={shopUrl}
              // onChange={(e) => setShopUrl(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
            <div className="flex justify-center space-x-4 mt-1">
              <Button
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent backdrop closure
                  closeShopifyModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleShopify()
                }}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )
      }

      {
        isDetailsOverlayOpen &&
        (
          <ModalBottomOverlay
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            header={
              <div className="flex justify-between">
                <div className="flex gap-4">
                  {handleIntegrationData()?.overLayIcon}
                  <div>
                    <h4>{handleIntegrationData()?.displayName}</h4>
                    <h5 className="font-normal">Instantly get automated reports and alerts</h5>
                    <p>Last Sync: {handleIntegrationData()?.lastSynced}</p>
                    <Button
                      color="primary"
                      onClick={() => setIsExpanded(!isExpanded)}
                      variant="text" icon={<ChevronDownIcon className={isExpanded ? "rotate-180" : ""} />}>
                      {isExpanded ? "Hide Sync History" : "View Sync History"}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col justify-end">
                  <div className="flex gap-3">
                    <Button
                      danger
                      icon={<CloseIcon className="w-2.5 h-2.5" />}
                      onClick={() => {
                        openDeleteModal(handleIntegrationData()?.integrationName);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            }
            onClose={closeDetailsOverlayModal}
            onSave={closeDetailsOverlayModal}
          >
            <div className="content-feature grid grid-cols-1 divide-y space-y-5 p-4 divide-gray-300">
              <div>
                <h4>About {handleIntegrationData()?.displayName}</h4>
                <p className="font-normal">We’ll alert you via <span className="font-medium">{userEmail}</span> if there is any unusual activity on your account.</p>
              </div>
              <div className="pt-4 px-4">
                <ul className="list-disc ml-2 text-sm text-gray-900">
                  {handleIntegrationData()?.aboutOauth?.length > 0 ? (
                    handleIntegrationData().aboutOauth.map((feature, index) => (
                      <li key={index}>{feature}</li>
                    ))
                  ) : (
                    <li>No features available</li>
                  )}
                </ul>
              </div>
            </div>
            <div className="content-container border-t px-10 p-4 border-gray-300 grid grid-cols-1 divide-y space-y-1 divide-gray-300">
              <div className="pb-3">
                <h4>Sync History</h4>
                <p>We’ll alert you via <span className="font-medium">{userEmail}</span> if there is any unusual activity on your account.</p>
              </div>
              {handleIntegrationData()?.syncHistory?.data.map((entry, index) => (
                <div key={index} className="flex gap-3 items-center py-5">
                  <p className="font-medium">{new Date(entry.date)
                    .toLocaleString('en-US',
                      {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      }).replace(',', ' at')}
                  </p>
                  {index === 0 && <Badge title='Last Sync' variant='active' />}
                </div>
              ))}
            </div>
          </ModalBottomOverlay>
        )
      }

      {
        isDeleteOpen && (
          <Modal
            onClose={() => {
              closeDeleteModal();
            }}
            onSave={() => {
              handleRemove();
            }}
            className="xl:max-w-[60%] lg:max-w-[70%] md:max-w-[75%]"
          >
            <div className="flex flex-row items-start gap-5">
              <IconRings numRings={5} ringBaseSize={48} ringSpacing={56} className=" -z-10">
                <div className="bg-[#FEE4E2] p-3 w-fit h-fit rounded-full">
                  <TrashIcon className="text-[#D92D20] w-5 h-5" />
                </div>
              </IconRings>
              <div className="z-10">
                <h4 className=" text-nowrap">Delete Integration</h4>
                <p className="text-sm">Are you sure you want to delete this integration? This action cannot be undone.</p>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center pt-10">
              <Checkbox
                control={control}
                label={'Don’t show again'}
                name="dontShowAgain"
                rules={{
                  required: false,
                }}
              />
              <div className="space-x-4">
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent backdrop closure
                    closeDeleteModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent backdrop closure
                    handleRemove();
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
        )
      }

      {
        isOauthOpen && (
          <Modal
            defaultClose={false}
            onSave={handleSubmitApprove}
            className="xl:max-w-[60%] lg:max-w-[70%] md:max-w-[75%]"
          >
            <div className="flex flex-row items-start gap-5">
              <IconRings numRings={5} ringBaseSize={48} ringSpacing={56} className=" -z-10">
                <div className="bg-[#DCFAE6] p-3 w-fit h-fit rounded-full">
                  <MagicWandIcon className="w-5 h-5" />
                </div>
              </IconRings>
              <div className="z-10">
                <h4 className=" text-nowrap">Successfully connected</h4>
                <p className="text-sm">Your {handleIntegrationData()?.displayName || 'Integration'} Data is not reay for Oogw.ai dashboard.</p>
              </div>
            </div>
            <div className="flex flex-row justify-end items-center pt-10">
              <div>
                <Button
                  type="primary"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent backdrop closure
                    handleSubmitApprove();
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

export default FirstIntegrations;