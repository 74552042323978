import React from "react";

interface IconRingsProps {
  numRings?: number;
  ringSpacing?: number;
  ringBaseSize?: number;
  children?: React.ReactNode;
  className?: string;
  ringColor?: string;
}

const IconRings = ({ numRings = 8, ringColor = "#E9EAEB", ringSpacing = 50, ringBaseSize = 50, children, className = '' }: IconRingsProps) => {
  const rings = Array.from({ length: numRings }, (_, index) => {
    const size = ringBaseSize + (index + 1) * ringSpacing; // Start from the second ring
    const opacity = 1 - (index / (numRings - 1)); // Adjust opacity dynamically to start from 1 and end at 0
    return (
      <div
        key={index}
        className={`absolute circle rounded-full border ${className}`}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          opacity,
          borderColor: ringColor
        }}
      />
    );
  });

  return (
    <div className={`flex items-center justify-center logo-container-rings`}>
      <div className="flex circle w-12 h-12 justify-center items-center">
        {/* Replace with your actual logo component */}
        <span>{children}</span>
      </div>
      {rings}
    </div>
  );
};

export default IconRings;
