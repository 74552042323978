
import './style.css'
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TickFormatter } from 'recharts/types/cartesian/CartesianAxis';
// In the file where you define BarChart, rename the function to MyBarChart

interface BarChartProps {
  data: any[];
  type: 'bar' | 'line';
  dataXkey: string;
  dataYKey: string;
  xAxisLabel: string;
  yAxisLabel: string;
  tickXFormatter?: TickFormatter;
  tickYFormatter?: TickFormatter;
}

function CustomChart({
  data,
  type,
  dataXkey,
  dataYKey,
  xAxisLabel,
  yAxisLabel,
  tickXFormatter,
  tickYFormatter,
}: BarChartProps) {

  const ChartWrapper = type === 'bar' ? BarChart : AreaChart;

  return (
    <ResponsiveContainer width={"100%"} height={380}>
      <ChartWrapper
        data={data}
        margin={{ top: 50, left: 50, right: 50, bottom: 50 }}
      >
        <defs>
          <pattern id="stripes" width="10" height="5" patternUnits="userSpaceOnUse">
            <rect width="2" height="5" fill="#E9EAEB" />
          </pattern>
        </defs>
        {type === 'bar' ? (
          <Bar animationDuration={50} barSize={8} strokeWidth={0} radius={[ 10, 10, 0, 0]} dataKey={'value'} stroke="#535862" fill="#279964" />
        ) : (
          <Area animationDuration={50}  strokeWidth={3} dataKey={dataYKey} stroke="#535862" fill="url(#stripes)" />
        )}
        <XAxis
          tickMargin={12}
          tick={{
            fill: "#535862",
          }}
          interval={0}
          stroke="#E9EAEB"
          dataKey={dataXkey}
          tickLine={false}
          tickFormatter={(value, index) => {
            return tickXFormatter ? String(tickXFormatter(value, index)) : String(value);
          }}
        >
          <Label
            value={xAxisLabel}
            offset={-25}
            position="insideBottom"
            className="font-medium text-[#535862]"
            style={{ textAnchor: 'middle', dominantBaseline: 'middle' }}
          />
        </XAxis>
        <YAxis
          dataKey={dataYKey}
          tickMargin={6}
          axisLine={false}
          color="#E9EAEB"
          tickLine={false}
          tickFormatter={(value, index) => {
            return tickYFormatter ? String(tickYFormatter(value, index)) : String(value);
          }}
        >
          <Label
            value={yAxisLabel}
            angle={-90}
            position="insideLeft"
            className="font-medium text-[#535862]"
            offset={-25}
            style={{ textAnchor: 'middle', dominantBaseline: 'middle' }}
          />
        </YAxis>
        <Tooltip />
        <CartesianGrid stroke="#E9EAEB" vertical={false} />
      </ChartWrapper>
    </ResponsiveContainer>
  )
}

export default CustomChart;
