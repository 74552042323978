import { baseApi } from "./baseQuery";

const authApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => ({
        url: "api/v1/login",
        method: "POST",
        headers:body
      }),
    }),

    register: build.mutation({
      query: (payload) => ({
        url: "api/v1/register",
        method: "POST",
        body:payload.data,
        headers:payload.body
      }),
    }),

    notification: build.mutation({
      query: (payload) => ({
        url: "api/v1/user-notification-settings",
        method: "GET",
        body:payload
      }),
    }),
  }),

  // @ts-ignore
  overrideExisting: module.hot?.status() === "apply",
});

export const { 
    useLoginMutation,
    useRegisterMutation,
    useNotificationMutation,
 } = authApi;
