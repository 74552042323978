export const validationConstants = {
  name: {
    minLength: {
      value: 2,
      message: 'Name must be at least 2 characters',
    },
    maxLength: {
      value: 50,
      message: 'Name must be at most 50 characters',
    },
    pattern: {
      value: /^[a-zA-Z\s]+$/i,
      message: 'Name must contain only letters and spaces',
    },
  },
  firstName: {
    minLength: {
      value: 2,
      message: 'Name must be at least 2 characters',
    },
    maxLength: {
      value: 50,
      message: 'Name must be at most 50 characters',
    },
    pattern: {
      value: /^[a-zA-Z\s]+$/i,
      message: 'Name must contain only letters and spaces',
    },
  },
  lastName: {
    minLength: {
      value: 1,
      message: 'Name must be at least 2 characters',
    },
    maxLength: {
      value: 50,
      message: 'Name must be at most 50 characters',
    },
    pattern: {
      value: /^[a-zA-Z\s]+$/i,
      message: 'Name must contain only letters and spaces',
    },
  },
  phoneno:{
    pattern:{
      value: /^(0|[1-9]\d*)(\.\d+)?$/,
      message:"Phone No must be number"
   },
  },
  required: {
    value: true,
    message: 'This field is required',
  },
  email: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Invalid email address',
  },
  password: {
    minLength: {
      value: 6,
      message: 'Password must be at least 6 characters',
    },
    // maxLength: {
    //   value: 20,
    //   message: 'Password must be at most 20 characters',
    // },
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      message: 'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
    },
  },
  confirmPassword: {
    value: (value, values) => value === values.password,
    message: 'Passwords do not match',
  },
};