import React from 'react'
import Icons from '../Icons';


const ToastComponent = ({ id, message, type, onClose, duration = 3000 }) => {
  const toastTypes = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    warning: 'bg-yellow-500 text-white',
    info: 'bg-blue-500 text-white',
    default: 'bg-gray-500 text-white',
  }

  const [fade, setFade] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setFade(true);
    }, duration - 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  return (

    <div
      className={`flex items-center rounded-md justify-between gap-1 w-fit top-0 right-0 z-10 p-4 ${toastTypes[type]} transition-opacity duration-150 ${fade ? 'opacity-0' : ''}`}
    >
      <p className="text-sm">{message}</p>
      <Icons
        icon={'heroicons-outline:x-circle'}
        onClick={onClose}
        className="w-4 h-4"
      />
    </div>
  )
}

export default ToastComponent