import React, { useRef, useState, useEffect, ReactNode } from "react";
import { Button } from "../Buttons";
import { Switch } from 'antd';

interface TableProps<T> {
  data: T[];
  renderHeader: () => ReactNode;
  renderRow: (row: T, index: number) => ReactNode;
  rowHeight?: number;
  buffer?: number;
}

interface TableTeamProps {
  data: TeamMember[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  updateToggle:(checked:any)=>void;
}

interface TeamMember {
  id: string;
  name: string;
  email: string;
  profile_picture: string;
  admin:boolean;
}

const Table = <T,>({
  data = [],
  renderHeader,
  renderRow,
  rowHeight = 50,
  buffer = 5,
}: TableProps<T>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const [scrollTop, setScrollTop] = useState<number>(0);

  const totalHeight = data.length * rowHeight;

  const handleScroll = () => {
    if (containerRef.current) {
      setScrollTop(containerRef.current.scrollTop);
    }
  };

  const updateViewportHeight = () => {
    if (containerRef.current) {
      setViewportHeight(containerRef.current.clientHeight);
    }
  };

  useEffect(() => {
    updateViewportHeight();
    window.addEventListener("resize", updateViewportHeight);
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);

  const startIndex = Math.max(0, Math.floor(scrollTop / rowHeight) - buffer);
  const endIndex = Math.min(
    data.length,
    Math.ceil((scrollTop + viewportHeight) / rowHeight) + buffer
  );

  const visibleData = data.slice(startIndex, endIndex);
  const offsetY = startIndex * rowHeight;

  return (
    <div
      className="relative overflow-x-auto border-2 border-gray-200 rounded-xl"
      ref={containerRef}
      onScroll={handleScroll}
      style={{ height: "400px" }}
    >
      {/* Table */}
      <table className="w-full text-sm text-left rtl:text-right">
        {/* Header */}
        <thead className="text-gray-700 sticky top-0 z-10 capitalize border-b-2 border-gray-200 bg-gray-50">
          <tr>
            {renderHeader()}
          </tr>
        </thead>

        {/* Rows */}
        <tbody className="relative">
          <tr style={{ height: offsetY, visibility: "hidden" }}></tr>
          {visibleData.map((row, index) => (
            <React.Fragment key={index}>
              {renderRow(row, startIndex + index)}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TableTeam = ({ data, onEdit, onDelete,updateToggle }: TableTeamProps): JSX.Element => {
  const handleEdit = (id: string) => {
    try {
      onEdit(id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (id: string) => {
    try {
      onDelete(id);
    } catch (error) {
      console.error(error);
    }
  };

  const renderHeader = () => (
    <div className="flex items-center py-3 px-6 font-bold bg-gray-100 text-sm">
      <div className="basis-6/12">Name</div>
      <div className="basis-3/12">Email</div>
      <div className="basis-3/12">Admin</div>
    </div>
  );

  const renderRow = (row: TeamMember, index: number) => (
    <>
      <div
        key={index}
        className={`flex items-center px-6 py-4 bg-white border-b border-gray-200`}
      >
        <div className="flex items-center basis-6/12 gap-3">
          <img className="w-12 h-12 rounded-full" src={row?.profile_picture} alt={''} />
          <div className="text-[#181D27] text-sm font-medium">{row?.name}</div>
        </div>
        <div className="flex items-center basis-3/12">
          <div className="text-[#535862] text-sm font-normal">{row?.email}</div>
        </div>
        <div className="flex items-center basis-3/12">
        <Switch defaultChecked value={row?.admin} onChange={(checked:boolean)=>{
          let payload={
            "member_email":row?.email,
            "admin": checked
        }
          updateToggle(payload)
        }} className="teamSwitch"/>
          <Button
            type="text"
            onClick={() => handleDelete(row.email)}
          >
            Delete
          </Button>
          {/* <Button
            color="primary"
            variant="text"
            onClick={() => handleEdit(row.id)}
          >
            Edit
          </Button> */}
        </div>
      </div>
      {index !== data.length - 1 && <div className="h-px bg-gray-200" />}
    </>
  );

  return (
    <div>
      <Table
        data={data}
        renderHeader={renderHeader}
        renderRow={renderRow}
      />
    </div>
  );
};

export { Table, TableTeam };
