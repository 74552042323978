import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, Group, TextInput, Title } from "@mantine/core";
import "./paymentCard.css";
import InputField from "../InputField";
import { VisaLogo } from "../Icons/iconImages";
import { Button } from "../Buttons";

export interface PaymentFormValues {
    name: string;
    card_number: string;
    expiry_date: string;
    cvv: string;
}

interface Props {
    onSubmitData: (e: PaymentFormValues) => void;
    onClose: () => void;
    edit: boolean;
    isLoading?: boolean;
    currentCard: any;
}

const PaymentForm: React.FC<Props> = ({
    onSubmitData,
    onClose,
    edit,
    currentCard,
    isLoading,
}) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm<PaymentFormValues>({
        defaultValues: {
            name: "",
            card_number: "",
            expiry_date: "",
            cvv: "",
        },
    });

    const onSubmit = (data: PaymentFormValues) => {
        console.log("Processing payment...", data);
        onSubmitData(data)
    };

    useEffect(() => {
        if (edit) {
            setValue("card_number", currentCard?.card_number)
            setValue("expiry_date", currentCard?.expiry_date)
            setValue("cvv", currentCard?.cvv)
            setValue("name", currentCard?.name)
        }
    }, [edit, currentCard])

    const formatCardNumber = (value: string) => {
        return value
            .replace(/\D/g, "")
            .replace(/(.{4})/g, "$1 ")
            .trim();
    };

    const formatExpiryDate = (value: string) => {
        const numericValue = value.replace(/\D/g, "");
        const formattedValue = numericValue.slice(0, 4);

        if (formattedValue.length > 2) {
            return formattedValue.slice(0, 2) + "/" + formattedValue.slice(2);
        } else {
            return formattedValue;
        }
    };

    return (
        <Box className="mt-4">
            <form onSubmit={handleSubmit(onSubmit)} className="paymentForm">
                <div className="space-y-4">
                    <p className='pt-5 font-semibold text-[#414651] text-sm'>Card details <span className='text-green-500'>*</span></p>
                    <InputField
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        label={"Name on card"}
                        placeholder={"Enter your name"}
                        error={errors.name?.message}
                    />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6">
                            <InputField
                                label={"Card number"}
                                name="card_number"
                                control={control}
                                rules={{ required: "Card number is required" }}
                                placeholder={"1234 1234 1234 1234"}
                                error={errors.card_number?.message}
                                iconDirection="left"
                                icon={<VisaLogo className="w-9 h-auto mr-1" />}
                            />
                        </div>
                        <div className="col-span-3">
                            <InputField
                                name="expiry_date"
                                control={control}
                                rules={{ required: "Expiry date is required" }}
                                label={"Expiry"}
                                placeholder="MM/YY"
                                error={errors.expiry_date?.message}
                                onChange={(e) => setValue("expiry_date", formatExpiryDate(e.target.value))}
                            />
                        </div>

                        <div className="col-span-3">
                            <InputField
                                name="cvv"
                                control={control}
                                rules={{
                                    required: "CVV is required",
                                    pattern: {
                                        value: /^[0-9]{3,4}$/,
                                        message: "Invalid CVV",
                                    },
                                }}
                                label={"CVV"}
                                placeholder="•••"
                                maxLength={4}
                                error={errors.cvv?.message}
                            />
                        </div>

                    </div>
                </div>
                <div className="flex justify-between mt-6 gap-5">
                    <Button
                        className="w-full"
                        onClick={() => onClose()}
                        disabled={isLoading}
                        loading={false}
                    >
                        Cancel
                    </Button>
                    <Button
                        htmlType="submit"
                        type='primary'
                        className="w-full"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={() => onSubmitData}
                    >
                        Save Details
                    </Button>
                </div>
            </form>
        </Box>
    );
};

export default PaymentForm;
