import { useState, useRef } from "react";
import "./styles.css";
import { Control, Controller, FieldError } from "react-hook-form";

interface TextAreaProps {
  control: Control<any>;
  error?: FieldError | undefined;
  label: string;
  rules?: {
    required?: any;
    [key: string]: any;
  };
  placeholder?: string;
  className?: string;
  id?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  cols?: number;
}

function TextAreaComponent({
  label,
  placeholder = "",
  className = "",
  id,
  name = '',
  rules,
  control,
  value,
  onChange,
  error,
  rows = 5,
  cols = 20,
  defaultValue,
  ...rest
}: TextAreaProps) {
  const [dimensions, setDimensions] = useState({ width: 300, height: rows * 24 }); // Adjust default height based on rows
  const containerRef = useRef(null);

  return (
    <label htmlFor={id} className="block" ref={containerRef}>
      {label && (
        <span className="block mb-1 text-sm font-medium text-gray-700">
          {label}
        </span>
      )}
      <Controller
          name={name}
          rules={rules}
          control={control}
          render={({ field, fieldState: { error: fieldError } }) => (
            <textarea
              id={id}
              name={name}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              style={{ width: "100%", height: dimensions.height }}
              className={`${className} ${
                error ? "border-red-500" : "focus:border-gray-500 border-[#D5D7DA]"
              }`}
              placeholder={placeholder}
              {...rest}
            />
          )}
        />
    </label>
  );
}

export default TextAreaComponent;
