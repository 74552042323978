import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';

// SCREENS
import Loading from './components/Loading';
import Dashboard from './pages/Dashboard';
import Integrations from './pages/Integrations';
import SignUpScreen from './pages/SignUp';
import CompanySettings from './pages/CompanySettings';
import Billing from './pages/CompanySettings/Billing';
import GeneralSettings from './pages/CompanySettings/GeneralSettings';
import Notifications from './pages/CompanySettings/Notifications';
import Plan from './pages/CompanySettings/Plan';
import Security from './pages/CompanySettings/Security';
import Teams from './pages/CompanySettings/Teams';
import FirstIntegrations from './pages/Integrations/firstIntegration';



// CONTEXT
import { ToastProvider } from './components/Toast/ToastContext';
import { SidebarProvider } from './components/Sidebar/SidebarContext';

// COMPONENTS
import PublicRoute from './appLayout/publicLayout';
import PrivateRoute from './appLayout/privateLayout';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginScreen = lazy(() => import('./pages/Login'));
const ViewRawData = lazy(() => import('./pages/Integrations/viewRawData'));

function App() {
  const [loading, setLoading] = React.useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const basePath = urlParams.get("basepath");
  const stateParam = urlParams.get("state")?.split(':')[1]; // for GA

  if (loading) {
    return <Loading />;
  }
  return (
    <main className="h-screen">
      <ToastContainer />
      {/* <DynamicTitle isLoading={false} isOffline={false} /> */}
      <ToastProvider>
        <SidebarProvider>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route element={<PublicRoute />}>
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/signup" element={<SignUpScreen />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="onboardingIntegration" element={<FirstIntegrations />} />
                {(basePath === "onboardingintegration" || stateParam === "onboardingintegration") ? (
                  <>
                    <Route path="integrations" element={<FirstIntegrations />} />
                    <Route path="oauth/google" element={<FirstIntegrations />} />
                    <Route path="/oauth/googleaAds" element={<FirstIntegrations />} />
                    <Route path="api/v1/shopify/auth" element={<FirstIntegrations />} />
                    <Route path="/meta/auth" element={<FirstIntegrations />} />
                  </>
                ) : (
                  <>
                    <Route path="oauth/google" element={<Integrations />} />
                    <Route path="/oauth/googleaAds" element={<Integrations />} />
                    <Route path="api/v1/shopify/auth" element={<Integrations />} />
                    <Route path="/meta/auth" element={<Integrations />} />
                    <Route path="integrations" element={<Integrations />} />
                  </>
                )}
                <Route
                  path="integrations/view-raw-data/:integrationId"
                  element={<ViewRawData />}
                />
                <Route path="company-settings" element={<CompanySettings />}>
                  <Route path="general" element={<GeneralSettings />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="security" element={<Security />} />
                  <Route path="teams" element={<Teams />} />
                  <Route path="plan" element={<Plan />} />
                  <Route path="billing" element={<Billing />} />
                </Route>
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Route>
              <Route index element={<Navigate to="/login" replace />} />
            </Routes >
          </Suspense>
        </SidebarProvider>
      </ToastProvider>
    </main>
  );
}


export default App;
