import { useEffect, useRef, useState } from "react";
import useModal from "../../Hooks/useModal";
import { Button } from "../Buttons";
import { Button as AntButton } from "antd";
import { CloseIcon } from "../Icons/iconImages";
import gsap from "gsap";

interface ModalProps {
  onSave: () => void;
  onClose?: () => void;
  children: React.ReactNode;
  saveLabel?: string;
  defaultFooter?: boolean;
  className?: string;
  timer?: number;
  defaultClose?: boolean;
}

const Modal = ({ onSave, onClose, children, saveLabel = "Allow", defaultFooter, className = '', timer, defaultClose = true }: ModalProps) => {
  const { closeModal } = useModal();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const backdropRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    if (modalRef.current) {
      gsap.to(modalRef.current, {
        y: "100%",
        opacity: 0,
        duration: 0.3,
        ease: "power2.in",
        onComplete: () => {
          if (onClose) {
            onClose();
          } else {
            onSave();
          }
        },
      });
    }
  };

  useEffect(() => {
    if (modalRef.current) {
      gsap.fromTo(
        modalRef.current,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0, duration: 0.3 }
      );
    }
  }, []);

  useEffect(() => {
    // Animate modal and backdrop on mount
    gsap.fromTo(
      modalRef.current,
      { y: "100%", opacity: 0 },
      { y: "0%", opacity: 1, duration: 0.2, ease: "power1.out" }
    );
    gsap.fromTo(
      backdropRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.2 }
    );
  }, []);

  useEffect(() => {
    if (timer) {
      const timerId = setInterval(() => {
        handleClose();
      }, timer);
      return () => clearInterval(timerId);
    }
  }, [timer]);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black-900 bg-opacity-50"
      onClick={() => {
        handleClose();
      }}
      ref={backdropRef}
    >
      <div ref={modalRef} className={`bg-white rounded-2xl shadow-lg p-6 ${className} overflow-hidden`}>
        {defaultClose && <AntButton
          className="absolute top-0 right-0 mt-2 mr-2"
          shape="circle"
          icon={<CloseIcon />}
          onClick={handleClose}
          type="text"
        />}
        {children}
        {defaultFooter && (
          <div className="flex gap-2 justify-center mt-4">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onSave}
            >
              {saveLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

interface ModalBottomOverlayProps {
  onSave: () => void;
  onClose: () => void;
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  isExpanded?: boolean;  // expansion state
  setIsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalBottomOverlay = ({
  onClose,
  header,
  footer,
  children,
  isExpanded = false, // Provide a default value
  setIsExpanded = () => { }, // Provide a default no-op function
}: ModalBottomOverlayProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const backdropRef = useRef<HTMLDivElement | null>(null);

  const [modalHeight, setModalHeight] = useState("auto");

  const handleClose = () => {
    gsap.to(modalRef.current, {
      y: "100%",
      opacity: 0,
      duration: 0.3,
      ease: "power2.in",
      onComplete: () => {
        onClose();
        if (isExpanded) setIsExpanded(false);
      },
    });
  };

  useEffect(() => {
    // Animate modal and backdrop on mount
    gsap.fromTo(
      modalRef.current,
      { y: "100%", opacity: 0 },
      { y: "0%", opacity: 1, duration: 0.2, ease: "power1.out" }
    );
    gsap.fromTo(
      backdropRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.2 }
    );
  }, []);

  useEffect(() => {
    const modal = modalRef.current;
    if (!modal) return;

    // initial modal height
    const contentFeature = modal.querySelector(".content-feature") as HTMLElement;
    const headerContainer = modal.querySelector(".header-container") as HTMLElement;

    const contentHeight = contentFeature?.offsetHeight || 0;
    const headerHeight = headerContainer?.offsetHeight || 0;
    const calculatedInitialHeight = `${contentHeight + headerHeight}px`;

    // modal height based on expansion
    const contentContainer = modal.querySelector(".content-container");
    if (!contentContainer) return;

    const expandedHeight = "100%";
    const collapsedHeight = calculatedInitialHeight;

    setModalHeight(isExpanded ? expandedHeight : collapsedHeight);

    // scroll to the content-container when expanded
    if (isExpanded) {
      contentContainer.scrollIntoView({ behavior: "smooth" });
    }

    const handleScroll = () => {
      if (isExpanded) return;
      const { scrollTop, scrollHeight, clientHeight } = modal;
      // Expand when almost scrolled to the bottom area
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setIsExpanded(true);
      }
    };

    modal.addEventListener("scroll", handleScroll);

    // Clean-up on unmount
    return () => {
      modal.removeEventListener("scroll", handleScroll);
    };
  }, [isExpanded]);

  return (
    <div
      ref={backdropRef}
      className="bottom-0 fixed inset-0 z-50 flex items-end justify-center bg-black-900 bg-opacity-50"
      onClick={(e) => {
        if (e.target === backdropRef.current) handleClose();
      }}
    >
      <div
        ref={modalRef}
        style={{
          maxHeight: modalHeight,
          overflowY: "auto",
          transition: "max-height 0.5s ease-in-out",
        }}
        onClick={(e) => e.stopPropagation()}
        className="relative rounded-t-2xl bg-white shadow-lg xl:w-7/12 md:w-10/12 w-11/12"
      >
        <div className="header-container bg-gray-100 rounded-t-2xl p-4 sticky top-0 z-10">
          {header}
          <AntButton
            className="absolute top-0 right-0 mt-2 mr-2"
            shape="circle"
            icon={<CloseIcon />}
            onClick={handleClose}
            type="text"
          />
        </div>
        <div>{children}</div>
        {footer && <div className="bg-gray-100 p-4">{footer}</div>}
      </div>
    </div>
  );
};

export { Modal, ModalBottomOverlay };
